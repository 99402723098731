import Form from 'react-bootstrap/Form';
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {registerMatchNum} from "../../store/actions/matchAction";
import matchReducer from "../../store/reducers/matchReducer";
function CustomRadio() {
    const [ number, setNumber ] = useState(5);
    const dispatch = useDispatch();

    const select = (e) => {
        setNumber(e.target.value);
        console.log(number);
        dispatch(registerMatchNum(e.target.value));
    }
    return (
        <div id="div-CustomRadio">
            <Form>
                <Form.Check
                    inline
                    label="5 vs 5"
                    type="radio"
                    id="inline-radio-1"
                    name="group1"
                    value={5}
                    onClick={select}
                    defaultChecked={true}
                />
                <Form.Check
                    inline
                    label="6 vs 6"
                    type="radio"
                    id="inline-radio-2"
                    name="group1"
                    value={6}
                    onClick={select}
                />
            </Form>
        </div>
    )
}

export default CustomRadio;