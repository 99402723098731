import JwgioLogo from "../../assets/img/FCJWGIO_LOGO_512.png";
import React from "react";
import "./NoLoginButton.css";
import {useNavigate} from "react-router-dom";
function NoLoginButton() {
    const navigate = useNavigate();

    const click = e => {
        navigate("/main");
    }
    return (
            <div className="no-login-btn" onClick={click}>
                <a className="no-login-href"/>
                <div className="no-login-btn-symbol-div">
                    <img className="no-login-btn-symbol" src={JwgioLogo}/>
                </div>
                <div className="no-login-btn-label-div">
                     비회원 방문하기
                </div>
            </div>
        )
}

export default NoLoginButton;