import InputGroup from "react-bootstrap/InputGroup";
import Form from 'react-bootstrap/Form';
import "./MatchUrlForm.css";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {registerMatchLink} from "../../store/actions/matchAction";
function MatchUrlForm() {
    const [link, setLink] = useState();
    const dispatch = useDispatch();

    const change = (e) => {
        let { value } = { ...e.target }
        setLink(value);
        dispatch(registerMatchLink(value));
        console.log(value)
    }
    return(
        <div style={{marginTop: "1.5%"}}>
            <input id="MatchUrlInput" placeholder="신청 링크를 입력해주세요." onChange={change}/>
        </div>
    )
}

export default MatchUrlForm;