import backgroundImg from "../assets/img/jwgio_team_pic3.jpeg";
import React from "react";

function ErrorPage() {
    return (
        <div className="errorPage-wrapper">
            <div className="background-div">
                <img
                    className="background-img"
                    src={backgroundImg}
                />
                <div className="title">
                    <h1 className="title-text">FC 정우지오</h1>
                </div>
            </div>
        </div>
        )
}

export default ErrorPage;