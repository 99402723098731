import {PURGE} from "redux-persist/es/constants";

const initialState = {
    userInfo: { member_id: null, username: null },
    isNewMember: false,
    login: false,
    profile: { name: null, img: null, jersey_number: null, position: null }, //사용자 정보 update dto 용
    myPage: { name: null, imgUrl: null, jersey_number: null, position: null },
    newProfileImg: false,
    players: [],
    topGoals: [],
    topAssists: [],
    topRate: [],
    topManagerRate: [],
    topMatch: [],
    deviceToken: null,
}

export const IS_NEWMEMBER = "IS_NEWMEMBER";
export const SET_LOGIN = "SET_LOGIN";
export const SET_PROFILE = "SET_PROFILE";
export const SET_DEVICETOKEN = "SET_DEVICETOKEN";

export const UPDATE_MYPAGE = "UPDATE_MYPAGE";
export const IS_NEWPROFILEIMG = "IS_NEWPROFILEIMG";
export const RESET_USERSTATE = "RESET_USERSTATE";
export const READ_PLAYERS = "READ_PLAYERS";
export const READ_TOP_GOALS = "READ_TOP_GOALS";
export const READ_TOP_ASSISTS = "READ_TOP_ASSISTS";
export const READ_TOP_RATE = "READ_TOP_RATE";
export const READ_TOP_MANAGER_RATE = "READ_TOP_MANAGER_RATE";
export const READ_TOP_MATCH = "READ_TOP_MATCH";
function userReducer(state = initialState, action) {
    switch (action.type) {
        case PURGE: {
            return initialState;
        }
        case IS_NEWMEMBER :
            return {
                ...state,
                isNewMember: action.isNewMember
            }
        case SET_LOGIN :
            return {
                ...state,
                login: action.login,
            }
        case SET_PROFILE :
            return  {
                ...state,
                profile: action.profile
            }
        case SET_DEVICETOKEN :
            return {
                ...state,
                deviceToken: action.deviceToken
            }
        case UPDATE_MYPAGE :
            return {
                ...state,
                myPage: action.myPage
            }
        case IS_NEWPROFILEIMG:
            return {
                ...state,
                newProfileImg: action.newProfileImg
            }
        case RESET_USERSTATE:
            return initialState;
        case READ_PLAYERS:
            return {
                ...state,
                players: action.players.map((p) => state.players.push(p))
                // players: state.players.concat(action.players)
                // players: [action.players]
            }
        case READ_TOP_GOALS:
            return {
                ...state,
                topGoals: [action.topGoals]
            }
        case READ_TOP_ASSISTS:
            return {
                ...state,
                topAssists: [action.topAssists]
            }
        case READ_TOP_RATE:
            return {
                ...state,
                topRate: [action.topRate]
            }
        case READ_TOP_MANAGER_RATE:
            return {
                ...state,
                topManagerRate: [action.topManagerRate]
            }
        case READ_TOP_MATCH:
            return {
                ...state,
                topMatch: [action.topMatch]
            }
        default:
            return state;
    }
}

export default userReducer;