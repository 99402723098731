import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useState} from "react";
import CustomDropdown from "../dropdown/CustomDropdown";
import {positionDropdown, resultPositionDropdown, stadiumDropdown} from "../layout/ModalLayout";
import {Dropdown, DropdownButton, Stack} from "react-bootstrap";
import {useSelector} from "react-redux";
import {POSITIONS, STADIUMS} from "../dropdown/DropdownData";
import {registerMatchStadiumCode} from "../../store/actions/matchAction";
import {setProfile} from "../../store/actions/userAction";
import axios from "axios";
function MatchResultGoalAssist(props) {
    const quarterNum = props.quarterNum;
    const matchId = props.matchId;
    const [ updated, setUpdated ] = useState(props.updated);
    const [ goal, setGoal ] = useState(((updated === false) ? null : props.goal));
    const [ assist, setAssist ] = useState(((updated === false) ? null : props.assist));
    const [ selectedItem, setSelectedItem ] = useState(() => {
        if(props.positionIndex === 5) return "플레이한 포지션을 선택해주세요";
        else {
            const at = POSITIONS.at(props.positionIndex);
            return at.name;
        }
    });
    const [ positionIndex, setPositionIndex ] = useState();
    const { currentMatch } = useSelector(state => state.matchReducer);
    const { userInfo } = useSelector(state => state.loginUser);
    const click = async (e) => {
        e.preventDefault();
        if(goal === null || goal === "") {
            alert(quarterNum+"쿼터의 득점을 입력해주세요");
            return;
        } else if(assist === null || assist === "") {
            alert(quarterNum+"쿼터의 어시스트를 입력해주세요");
            return;
        } else if(positionIndex === undefined) {
            alert(quarterNum+"쿼터의 포지션을 선택해주세요");
            return
        }
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        await axios.put("/api/match/writeQuarterStats/" + matchId + "/" + quarterNum +"/" + goal + "/" + assist + "/" + positionIndex, config)
            .then((res) => {
                setGoal(res.data.goal);
                setAssist(res.data.assist);
                setSelectedItem(POSITIONS.at(res.data.positionIndex).name);
                setUpdated(res.data.updated);
            }).catch((err) => {
                console.log(err);
            })
    }
    const SelectItem = (eventKey, e) => {
        const idx = e.target.id;
        const at = POSITIONS.at(idx);
        setSelectedItem(at.name);
        setPositionIndex(Number(eventKey));
        console.log(Number(eventKey));
    }
    const dropdownItem = () => {
        return (
            POSITIONS.map((position) => (
                <Dropdown.Item disabled={updated} eventKey={position.index} key={position.index} id={position.index}>{position.name}</Dropdown.Item>
            ))
        )
    }
    return (
            <Card style={{ width: "auto", alignItems: "center", display: "flex", justifyContent: "center"}}>
                <Card.Body>
                    <Card.Title>{quarterNum} 쿼터</Card.Title>
                    <InputGroup className="mb-3" >
                        <Stack>
                            <Form.Control style={{marginBottom: "2%"}} width="auto" aria-label="goal" inputMode="numeric" placeholder="골"
                                          disabled={updated}
                                          onInput={(e) => {
                                              setGoal(e.target.value);
                                          }} defaultValue={goal}/>
                            <Form.Control style={{marginBottom: "2%"}} width="auto" aria-label="assist" inputMode="numeric" placeholder="어시스트"
                                          disabled={updated}
                                          onInput={(e) => {
                                              setAssist(e.target.value);
                                          }} defaultValue={assist}/>
                            <div id="dropdown-wrap-div">
                                <DropdownButton
                                    id="dropdown-basic-button"
                                    title={selectedItem}
                                    onSelect={(eventKey, e) => SelectItem(eventKey, e)}
                                >
                                    {dropdownItem()}
                                </DropdownButton>
                            </div>
                            <Button style={{marginTop: "2%"}} variant="primary" disabled={updated} onClick={click}>등록</Button>
                        </Stack>
                    </InputGroup>
                </Card.Body>
            </Card>

    );

}

export default MatchResultGoalAssist;