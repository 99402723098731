const initialState = {
    userInfo: { member_id: null, username: null, auth: null },
}
export const SET_USERINFO = "SET_USERINFO";
export const DO_LOGOUT = "DO_LOGOUT";

function loginUserReducer(state = initialState, action) {
    switch (action.type) {
        case SET_USERINFO :
            return {
                ...state,
                userInfo: action.userInfo
            }
        case DO_LOGOUT  :
            return initialState;

        default:
            return state;
    }
}

export default loginUserReducer;