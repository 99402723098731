import Modal from 'react-bootstrap/Modal';
import {Button} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {POSITIONS} from "../dropdown/DropdownData";
import {setIsNewMember, setIsNewProfileImg, updateMyPage} from "../../store/actions/userAction";
import {setCurrentMatch} from "../../store/actions/matchAction";

function ModalLayout(props) {
    const modalType = props.type;
    const { profile, newProfileImg } = useSelector(state => state.userReducer);
    const { matchRegister } = useSelector(state => state.matchReducer);
    const { userInfo } = useSelector(state => state.loginUser);
    const dispatch = useDispatch();
    const click = () => {
        if(modalType === playerInfoEditModal) {
            jerseyNumberCheck();
        }
        else if(modalType === matchRegisterModal) {
            registerMatch();
        }
    }
    const jerseyNumberCheck = async () => {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        await axios.get("/api/member/profile/jerseyNumberCheck/"+profile.jersey_number,config)
            .then((res) => {
                if(res.data.used === true) {
                    if(res.data.username === userInfo.username) {
                        uploadProfile();
                    } else {
                        alert(res.data.username+"님이 사용중인 등번호입니다.");
                        return;
                    }
                } else {
                    uploadProfile();
                }
            }).catch((err) => {
                dispatch(setIsNewProfileImg(false));
            })
    }
    const uploadProfile = async () => {
        if(profile.img === null) {
            alert('프로필 사진을 선택해주세요.');
            return;
        } else if(profile.name.length < 1) {
            alert('이름을 입력해주세요.');
            return;
        } else if(profile.jersey_number === null) {
            alert('1~99 사이의 등번호를 입력해주세요.');
            return;
        } else if(profile.position === null) {
            alert('포지션을 선택해주세요.');
            return;
        }
        alert('프로필 업로드');
        const formData = new FormData();
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const profileDto = {name : profile.name, number: profile.jersey_number, position: profile.position};
        formData.append('dto', new Blob([JSON.stringify(profileDto)], { type: "application/json" }));

        if(newProfileImg) {
            formData.append('img', profile.img);

            await axios.post("/api/member/profile/update", formData, config)
                .then((res) => {
                    let myPage =
                        {name: res.data.name,
                            imgUrl: res.data.imgUrl,
                            jersey_number: res.data.number,
                            position: POSITIONS.at(res.data.positionIndex)};
                    dispatch(updateMyPage(myPage));
                    dispatch(setIsNewProfileImg(false));
                }).catch((err) => {
                    console.log(err);
                    dispatch(setIsNewProfileImg(false));
                })
        } else {

            await axios.post("/api/member/profile/update", formData, config)
                .then((res) => {
                    let myPage =
                        {name: res.data.name,
                            jersey_number: res.data.number,
                            imgUrl: res.data.imgUrl,
                            position: POSITIONS.at(Number(res.data.positionIndex))};
                    dispatch(updateMyPage(myPage));
                }).catch((err) => {
                    console.log(err);
                })
        }
        props.onHide();
    }

    const registerMatch = async () => {
        console.log(matchRegister);
        if(userInfo.member_id === null) {
            alert("로그인해주세요!");
            return;
        }
        // const formData = new FormData();
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const data = {
            date: matchRegister.date,
            stadiumCode: matchRegister.stadiumCode,
            link: matchRegister.link,
            num: matchRegister.num
        }
        if(data.date === null) {
            alert("날짜를 선택해주세요");
            return;
        }
        else if(data.stadiumCode === null) {
            alert("경기장을 선택해주세요");
            return;
        }
        else if(data.link === null) {
            alert("경기 링크를 입력해주세요");
            return;
        }
        else if(data.num === null) {
            alert("경기 인원을 선택해주세요");
            return;
        }
        await axios.post("/api/match/register", data, config)
            .then((res) => {
                if(res.data.exist === true) {
                    alert('이미 다른 팀원이 매치를 등록했어요!');
                    return;
                }
                const currentMatch = {
                    match_id: res.data.match_id,
                    date: res.data.date,
                    stadiumIndex: res.data.stadiumIndex,
                    link: res.data.link,
                    num: res.data.num,
                    status: res.data.status,
                    player: [res.data.players[0]],
                    playerId: [res.data.playersId[0]],
                    playerImages: res.data.playerImages,
                    createdBy: res.data.createdBy
                }
                dispatch(setCurrentMatch(currentMatch));
                matchRegisterPush(userInfo.username, res.data.stadiumIndex, res.data.date);
            }).catch((err) => {
                console.log(err);
            });

        props.onHide();
    }

    const matchRegisterPush = async (username, stadiumIndex, date) => {
        await axios.get("/api/push/match/create/" + username + "/" + stadiumIndex + "/" + date)
            .then((res) => {

            }).catch((err) => {
                console.log(err);
            })
    }
    return(
        <Modal show={props.show} onHide={props.onHide} backdrop={modalType === playerInfoEditModal ? "static" : null}>
            {props.children}
            <Modal.Footer style={{justifyContent: "center", display: "flex", alignItems: "center", backgroundColor:"#343a40"}}>
                <Button onClick={click}>등록</Button>
            </Modal.Footer>
        </Modal>
    )
}
export const playerInfoEditModal = "PLAYER_INFO_EDIT_MODAL";
export const matchRegisterModal = "MATCH_REGISTER_MODAL";
export const stadiumDropdown = "STADIUM_DROPDOWN";
export const positionDropdown = "POSITION_DROPDOWN";

export const resultPositionDropdown = "RESULT_POSITION_DROPDOWN";
export default ModalLayout;