import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import MatchResultGoalAssist from './MatchResultGoalAssist';
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import QuarterResultWrite from "./QuarterResultWrite";
import MatchPlayerRating from "./MatchPlayerRating";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {setCurrentMatch} from "../../store/actions/matchAction";

function MatchResultWrite() {
    const { matchId } = useParams();
    const { currentMatch } = useSelector(state => state.matchReducer);
    const { userInfo } = useSelector(state => state.loginUser);
    const [ goalAssist, setGoalAssist ] = useState();
    const [ winLose, setWinLose ] = useState();
    const [ rating, setRating ] = useState();
    const dispatch = useDispatch();
    let quarterResults = [];
    let quarterStats = [];
    let matchRatings = [];

    useEffect( () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        async function fetchCurrentMatchData() {
            await axios.get("/api/match/currentMatch", config)
                .then((res) => {
                    if(res.data.empty === true) {
                        return;
                    }
                    console.log(res);
                    const currentMatch = {
                        match_id: res.data.match_id,
                        date: res.data.date,
                        stadiumIndex: res.data.stadiumIndex,
                        link: res.data.link,
                        num: res.data.num,
                        status: res.data.status,
                        player: res.data.players,
                        playerId: res.data.playersId,
                        playerImages: res.data.playerImages,
                        createdBy: res.data.createdBy
                    }
                    dispatch(setCurrentMatch(currentMatch));
                }).catch((err) => {
                    console.log(err);
                })
        }
        fetchCurrentMatchData();
        async function fetchData() {
            await axios.get("/api/match/readResultWrite/"+matchId+"/"+userInfo.member_id, config)
                .then((res) => {
                    //쿼터별 승패 , 쿼터별 사용자 스탯, 평점 로딩.
                    quarterResults = res.data.quarterResults;
                    quarterStats = res.data.quarterStats;
                    matchRatings = res.data.matchRatings;
                    setWinLose(res.data.quarterResults.map((item) =>
                        <QuarterResultWrite key={item.num} num={item.num} updated={item.updated} gf={item.gf} ga={item.ga} matchId={matchId}/>));
                    setGoalAssist(res.data.quarterStats.map((item) =>
                        <MatchResultGoalAssist key={item.quarterNum} quarterNum={item.quarterNum} goal={item.goal} assist={item.assist} positionIndex={item.positionIndex} updated={item.updated} matchId={matchId}/>));
                    setRating(res.data.matchRatings.map((item)=>
                        <MatchPlayerRating key={item.member_id} memberId={item.member_id} username={item.username} rate={item.rate} updated={item.updated} matchId={matchId}/>));

                }).catch((err) => {
                    console.log(err);
                })
        }
        // if(myPage.name === null) fetchData();
        fetchData();
    }, []);

    const quarterTab = () => {
        console.log(currentMatch.createdBy === userInfo.member_id);
        if(currentMatch.createdBy === userInfo.member_id) {
            return (
                <Tab eventKey="wl" title="승/패">
                    {winLose}
                </Tab>
            )
        }
    }
    const ratingTab = () => {
        if(userInfo.auth === "ROLE_USER" || "ROLE_MANAGER") {
            return (
                <Tab eventKey="rate" title="팀원 평점">
                    {rating}
                </Tab>
            )
        }
    }
    return (
        <Tabs
            defaultActiveKey="ga"
            id="justify-tab-example"
            justify
        >
            <Tab eventKey="ga" title="골/어시">
                {goalAssist}
            </Tab>

            {quarterTab()}
            {ratingTab()}
            {/*<Tab eventKey="rate" title="팀원 평점">*/}
            {/*    <MatchPlayerRating matchId={matchId}/>*/}
            {/*    <MatchPlayerRating matchId={matchId}/>*/}
            {/*    <MatchPlayerRating matchId={matchId}/>*/}
            {/*    <MatchPlayerRating matchId={matchId}/>*/}
            {/*    <MatchPlayerRating matchId={matchId}/>*/}
            {/*    <MatchPlayerRating matchId={matchId}/>*/}
            {/*    <Card>*/}
            {/*        <Button>등록</Button>*/}
            {/*    </Card>*/}
            {/*</Tab>*/}
            {/*<Tab eventKey="longer-tab" title="MVP 투표">*/}
            {/*    Tab content for Loooonger Tab*/}
            {/*</Tab>*/}
        </Tabs>
    );
}

export default MatchResultWrite;