import {
    CANCEL_CURRENT_MATCH,
    CANCEL_JOIN_CURRENT_MATCH,
    CHANGE_ACTIVE_QUARTER_RESULT,
    CURRENT_MATCH_LOADING, GLOBAL_LOADING,
    JOIN_CURRENT_MATCH, MATCH_ACCORDION_LOADING,
    PAGING_MATCHES,
    REGISTER_MATCH_DATE,
    REGISTER_MATCH_LINK,
    REGISTER_MATCH_NUM,
    REGISTER_MATCH_STADIUM,
    SET_CURRENT_MATCH
} from "../reducers/matchReducer";

// export const registerMatch = (matchRegister) => ({
//     type: REGISTER_MATCH,
//     matchRegister: matchRegister
// })

export const registerMatchDate = (date) => ({
    type: REGISTER_MATCH_DATE,
    date: new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString()
})
export const registerMatchStadiumCode = (stadiumCode) => ({
    type: REGISTER_MATCH_STADIUM,
    stadiumCode: stadiumCode,
})
export const registerMatchLink = (link) => ({
    type: REGISTER_MATCH_LINK,
    link: link
})
export const registerMatchNum = (num) => ({
    type: REGISTER_MATCH_NUM,
    num: Number(num)
})

export const setCurrentMatch = (currentMatch) => ({
    type: SET_CURRENT_MATCH,
    currentMatch: currentMatch
})

export const cancelCurrentMatch = () => ({
    type: CANCEL_CURRENT_MATCH
})

export const joinCurrentMatch = (username, player_id, profileImg) => ({
    type: JOIN_CURRENT_MATCH,
    player: username,
    playerId: player_id,
    profileImg: profileImg
})

export const cancelJoinCurrentMatch = (player, playerId) => ({
    type: CANCEL_JOIN_CURRENT_MATCH,
    player: player,
    playerId: playerId
})

export const pagingMatches = (matches, lastIndex) => ({
    type: PAGING_MATCHES,
    matches: matches,
    lastIndex: lastIndex
})

export const changeActiveQuarterResult = (activeQuarterResult) => ({
    type: CHANGE_ACTIVE_QUARTER_RESULT,
    activeQuarterResult: activeQuarterResult
})

export const currentMatchLoad = (loading) => ({
    type: CURRENT_MATCH_LOADING,
    loading: loading
})

export const matchAccordionLoad = (loading) => ({
    type: MATCH_ACCORDION_LOADING,
    loading: loading
})

export const globalLoading = (loading) => ({
    type: GLOBAL_LOADING,
    loading: loading
})