import PlayerTable from "../components/player/PlayerTable";
import React, {useEffect} from "react";
import axios from "axios";
import {pagingMatches} from "../store/actions/matchAction";
import HorizonLine from "../components/HorizonLine";

function Records() {

    return(
        <div>
            <PlayerTable title="득점 TOP 5" type="goal"/>
            <HorizonLine/>
            <PlayerTable title="팀원 평점 TOP 5" type="rate"/>
            <HorizonLine/>
            <PlayerTable title="감독 평점 TOP 5" type="managerRate"/>
            <HorizonLine/>
            <PlayerTable title="어시스트 TOP 5" type="assist"/>
            <HorizonLine/>
            <PlayerTable title="매치 출전 TOP 5" type="match"/>
        </div>
    )
}
export default Records;