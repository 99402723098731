import MainCarousel from "../components/main/MainCarousel";
import MatchAccordion from "../components/match/MatchAccordion";
import HorizonLine from "../components/HorizonLine";
import CurrentMatch from "../components/main/CurrentMatch";
import React, {useEffect, useState} from "react";
import ModalLayout, {playerInfoEditModal} from "../components/layout/ModalLayout";
import PlayerInfoEditModalBody from "../components/player/PlayerInfoEditModalBody";
import {useDispatch, useSelector} from "react-redux";
import {setIsNewMember} from "../store/actions/userAction";
import A2HS from "../a2hs/A2HS";
import PWAPrompt from 'react-ios-pwa-prompt';
import Loading from "./Loading";
import axios from "axios";
import {
    cancelCurrentMatch,
    currentMatchLoading,
    matchAccordionLoading,
    pagingMatches,
    setCurrentMatch
} from "../store/actions/matchAction";
import useAxios from "../api/api";
import MatchList from "../components/match/MatchList";

function Main(){
    const [modalShow, setModalShow] = useState(false);
    const {isNewMember, login} = useSelector(state => state.userReducer);
    const { currentMatch, currentMatchLoading, matchAccordionLoading, matches} = useSelector(state => state.matchReducer);
    const dispatch = useDispatch();
    const {getCurrent, getMatchList} = useAxios();

    useEffect(()=> {
        if(login && isNewMember) {
            alert("회원가입을 환영합니다! 선수 정보를 등록해주세요");
            setModalShow(true);
            dispatch(setIsNewMember(false));
        }
        getCurrent();
        if(matches.length === 0) {
            getMatchList();
        }
    },[]);

    if(currentMatchLoading === true && matchAccordionLoading === true) {
        return (

            <div className="main">
                <HorizonLine />
                <CurrentMatch />
                <HorizonLine />
                {/*<MatchAccordion/>*/}
                <MatchList />
                <ModalLayout show={modalShow} onHide={() => setModalShow(false)} type={playerInfoEditModal} children={<PlayerInfoEditModalBody newMember={true}/>}/>
            </div>

        );
    } else {
        return (
            <Loading />
        )
    }

}

export default Main;