import React, {useEffect, useState} from 'react';
import { MDBBadge, MDBListGroup, MDBListGroupItem } from 'mdb-react-ui-kit';
import axios from "axios";
import {pagingMatches} from "../../store/actions/matchAction";
import {useDispatch, useSelector} from "react-redux";
import {readPlayers} from "../../store/actions/userAction";
import {POSITIONS} from "../dropdown/DropdownData";
import {useNavigate} from "react-router-dom";

function PlayerTable(props) {
    const dispatch = useDispatch();
    const type = props.type;
    const [ playerList,  setPlayersList ] = useState();
    const [ topGoals, setTopGoals ] = useState();
    const [ topAssists, setTopAssists ] = useState();
    const [ topRates, setTopRates ] = useState();
    const [ topManagerRates, setTopManagerRates ] = useState();
    const [ topMatches, setTopMatches ] = useState();
    const navigate = useNavigate();
    // const { players } = useSelector(state => state.userReducer);
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    useEffect( () => {
        async function fetchData() {
            switch (type) {
                case "all": {
                    await axios.get("/api/member/read/all", config)
                        .then((res) => {
                            if(res.data.empty === true) {
                                alert("매치 기록이 없어요 ㅠㅠ");
                            } else {
                                renderPlayers(res.data);
                            }
                        }).catch((err) => {
                        })
                    break;
                }
                case "goal": {
                    await axios.get("/api/member/read/top/goal", config)
                        .then((res) => {
                            console.log(res);
                            if(res.data.empty === true) {

                            } else {
                                renderTopGoal(res.data);
                            }
                        }).catch((err) => {
                        })
                    break;
                }
                case "assist": {
                    await axios.get("/api/member/read/top/assist", config)
                        .then((res) => {
                            if(res.data.empty === true) {

                            } else {
                                renderTopAssist(res.data);
                            }
                        }).catch((err) => {
                            console.log(err);
                        })
                    break;
                }
                case "rate": {
                    await axios.get("/api/member/read/top/rate", config)
                        .then((res) => {
                            if(res.data.empty === true) {

                            } else {
                                renderTopRate(res.data);
                            }
                        }).catch((err) => {
                            console.log(err);
                        })
                    break;
                }
                case "managerRate": {
                    await axios.get("/api/member/read/top/managerRate", config)
                        .then((res) => {
                            if(res.data.empty === true) {

                            } else {
                                renderTopManagerRate(res.data);
                            }
                        }).catch((err) => {
                            console.log(err);
                        })
                    break;
                }
                case "match" : {
                    await axios.get("/api/member/read/top/match", config)
                        .then((res) => {
                            if(res.data.empty === true) {

                            } else {
                                renderTopMatch(res.data);
                            }
                        }).catch((err) => {
                            console.log(err);
                        })
                    break;
                }
            }
        }
        fetchData();
    }, []);

    const title = props.title;
    const renderPlayers = (players) => {
        setPlayersList(
            players.map((p) => {
               return <MDBListGroupItem key={p.member_id} className='d-flex justify-content-between align-items-center'>
                   <div className='d-flex align-items-center'>
                       <img
                           src={p.img}
                           alt=''
                           style={{ width: '45px', height: '45px' }}
                           className='rounded-circle'
                           onClick={() => navigate('/player/'+p.member_id)}
                       />
                       <div className='ms-3'>
                           <p className='fw-bold mb-1'>No.{p.jerseyNumber} {p.username}</p>
                           <p className='text-muted mb-0'>{p.authority === "ROLE_MANAGER" ? "감독" : "선수"}</p>
                       </div>
                   </div>
                   {renderPositionBadge(p.positionIndex)}
               </MDBListGroupItem>
            })
        )
    }
    const renderTopGoal = (players) => {
        setTopGoals(
            players.map((p) => {
                return <MDBListGroupItem key={p.member_id} className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex align-items-center'>
                        <img
                            src={p.img}
                            alt=''
                            style={{ width: '45px', height: '45px' }}
                            className='rounded-circle'
                            onClick={() => navigate('/player/'+p.member_id)}
                        />
                        <div className='ms-3'>
                            <p className='fw-bold mb-1'>No.{p.jerseyNumber} {p.username}</p>
                        </div>
                        <div className='ms-3'>
                            <p className='fw-bold mb-1' style={{textAlign: "right"}}> {p.totalGoal} 골</p>
                        </div>
                    </div>
                    {renderPositionBadge(p.positionIndex)}
                </MDBListGroupItem>
            })
        )
    }

    const renderTopAssist = (players) => {
        setTopAssists(
            players.map((p) => {
                return <MDBListGroupItem key={p.member_id} className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex align-items-center'>
                        <img
                            src={p.img}
                            alt=''
                            style={{ width: '45px', height: '45px' }}
                            className='rounded-circle'
                            onClick={() => navigate('/player/'+p.member_id)}
                        />
                        <div className='ms-3'>
                            <p className='fw-bold mb-1'>No.{p.jerseyNumber} {p.username}</p>
                        </div>
                        <div className='ms-3'>
                            <p className='fw-bold mb-1' style={{textAlign: "right"}}> {p.totalAssist} 어시스트</p>
                        </div>
                    </div>
                    {renderPositionBadge(p.positionIndex)}
                </MDBListGroupItem>
            })
        )
    }

    const renderTopRate = (players) => {
        setTopRates(
            players.map((p) => {
                return <MDBListGroupItem key={p.member_id} className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex align-items-center'>
                        <img
                            src={p.img}
                            alt=''
                            style={{ width: '45px', height: '45px' }}
                            className='rounded-circle'
                            onClick={() => navigate('/player/'+p.member_id)}
                        />
                        <div className='ms-3'>
                            <p className='fw-bold mb-1'>No.{p.jerseyNumber} {p.username}</p>
                        </div>
                        <div className='ms-3'>
                            <p className='fw-bold mb-1' style={{textAlign: "right"}}> {Number(p.rate).toFixed(1)} 점</p>
                        </div>
                    </div>
                    {renderPositionBadge(p.positionIndex)}
                </MDBListGroupItem>
            })
        )
    }

    const renderTopManagerRate = (players) => {
        setTopManagerRates(
            players.map((p) => {
                if(p.managerRate !== NaN) {
                    return <MDBListGroupItem key={p.member_id} className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex align-items-center'>
                            <img
                                src={p.img}
                                alt=''
                                style={{ width: '45px', height: '45px' }}
                                className='rounded-circle'
                                onClick={() => navigate('/player/'+p.member_id)}
                            />
                            <div className='ms-3'>
                                <p className='fw-bold mb-1'>No.{p.jerseyNumber} {p.username}</p>
                            </div>
                            <div className='ms-3'>
                                <p className='fw-bold mb-1' style={{textAlign: "right"}}> {Number(p.managerRate).toFixed(1)} 점</p>
                            </div>
                        </div>
                        {renderPositionBadge(p.positionIndex)}
                    </MDBListGroupItem>
                }
                // return <MDBListGroupItem key={p.member_id} className='d-flex justify-content-between align-items-center'>
                //     <div className='d-flex align-items-center'>
                //         <img
                //             src={p.img}
                //             alt=''
                //             style={{ width: '45px', height: '45px' }}
                //             className='rounded-circle'
                //             onClick={() => navigate('/player/'+p.member_id)}
                //         />
                //         <div className='ms-3'>
                //             <p className='fw-bold mb-1'>No.{p.jerseyNumber} {p.username}</p>
                //         </div>
                //         <div className='ms-3'>
                //             <p className='fw-bold mb-1' style={{textAlign: "right"}}> {Number(p.managerRate).toFixed(1)} 점</p>
                //         </div>
                //     </div>
                //     {renderPositionBadge(p.positionIndex)}
                // </MDBListGroupItem>
            })
        )
    }
    const renderTopMatch = (players) => {
        setTopMatches(
            players.map((p) => {
                return <MDBListGroupItem key={p.member_id} className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex align-items-center'>
                        <img
                            src={p.img}
                            alt=''
                            style={{ width: '45px', height: '45px' }}
                            className='rounded-circle'
                            onClick={() => navigate('/player/'+p.member_id)}
                        />
                        <div className='ms-3'>
                            <p className='fw-bold mb-1'>No.{p.jerseyNumber} {p.username}</p>
                        </div>
                        <div className='ms-3'>
                            <p className='fw-bold mb-1' style={{textAlign: "right"}}> {p.totalMatch} 경기</p>
                        </div>
                    </div>
                    {renderPositionBadge(p.positionIndex)}
                </MDBListGroupItem>
            })
        )
    }
    const renderPositionBadge = (positionIndex) => {
        switch (positionIndex) {
            case 0: {
                return (
                    <MDBBadge pill='true' color='success'>
                        {POSITIONS.at(positionIndex).position}
                    </MDBBadge>
                )
                break;
            }
            case 1: {
                return (
                    <MDBBadge pill='true' color="danger">
                        {POSITIONS.at(positionIndex).position}
                    </MDBBadge>
                )
                break;
            }
            case 2: {
                return (
                    <MDBBadge pill='true' color="dark">
                        {POSITIONS.at(positionIndex).position}
                    </MDBBadge>
                )
                break;
            }
            case 3: {
                return (
                    <MDBBadge pill='true' color="primary">
                        {POSITIONS.at(positionIndex).position}
                    </MDBBadge>
                )
                break;
            }
            case 4: {
                return (
                    <MDBBadge pill='true' color='warning'>
                        {POSITIONS.at(positionIndex).position}
                    </MDBBadge>
                )
                break;
            }
        }
    }
    return(
        <div>
            <h6 className='bg-light p-2 border-top border-bottom'>{title}</h6>
            <MDBListGroup style={{ minWidth: '22rem' }} light>
                {/*<MDBListGroupItem className='d-flex justify-content-between align-items-center'>*/}
                {/*    <div className='d-flex align-items-center'>*/}
                {/*        <img*/}
                {/*            src='https://mdbootstrap.com/img/new/avatars/8.jpg'*/}
                {/*            alt=''*/}
                {/*            style={{ width: '45px', height: '45px' }}*/}
                {/*            className='rounded-circle'*/}
                {/*        />*/}
                {/*        <div className='ms-3'>*/}
                {/*            <p className='fw-bold mb-1'>John Doe</p>*/}
                {/*            <p className='text-muted mb-0'>john.doe@gmail.com</p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <MDBBadge pill='true' color='success'>*/}
                {/*        Active*/}
                {/*    </MDBBadge>*/}
                {/*</MDBListGroupItem>*/}
                {/*<MDBListGroupItem className='d-flex justify-content-between align-items-center'>*/}
                {/*    <div className='d-flex align-items-center'>*/}
                {/*        <img*/}
                {/*            src='https://mdbootstrap.com/img/new/avatars/6.jpg'*/}
                {/*            alt=''*/}
                {/*            style={{ width: '45px', height: '45px' }}*/}
                {/*            className='rounded-circle'*/}
                {/*        />*/}
                {/*        <div className='ms-3'>*/}
                {/*            <p className='fw-bold mb-1'>Alex Ray</p>*/}
                {/*            <p className='text-muted mb-0'>alex.ray@gmail.com</p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <MDBBadge pill={true} color='primary'>*/}
                {/*        Onboarding*/}
                {/*    </MDBBadge>*/}
                {/*</MDBListGroupItem>*/}
                {/*<MDBListGroupItem className='d-flex justify-content-between align-items-center'>*/}
                {/*    <div className='d-flex align-items-center'>*/}
                {/*        <img*/}
                {/*            src='https://mdbootstrap.com/img/new/avatars/7.jpg'*/}
                {/*            alt=''*/}
                {/*            style={{ width: '45px', height: '45px' }}*/}
                {/*            className='rounded-circle'*/}
                {/*        />*/}
                {/*        <div className='ms-3'>*/}
                {/*            <p className='fw-bold mb-1'>Kate Hunington</p>*/}
                {/*            <p className='text-muted mb-0'>kate.hunington@gmail.com</p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <MDBBadge pill={true} color='warning'>*/}
                {/*        Awaiting*/}
                {/*    </MDBBadge>*/}
                {/*</MDBListGroupItem>*/}
                {topGoals}
                {topAssists}
                {topRates}
                {topManagerRates}
                {topMatches}
                {playerList}
            </MDBListGroup>
        </div>
    )
}

export default PlayerTable;