import React, {useEffect, useState} from 'react'
import "../css/MatchList.css"
import {useDispatch, useSelector} from "react-redux";
import NumberToDayOfWeekConverter from "../time/NumberToDayOfWeekConverter";
import {POSITIONS, STADIUMS} from "../dropdown/DropdownData";
import Accordion from "react-bootstrap/Accordion";
import {Badge, Stack} from "react-bootstrap";
import MatchDetailCarousel from "./MatchDetailCarousel";
import {setIsNewMember} from "../../store/actions/userAction";
import useAxios from "../../api/api";
import axios from "axios";
import {pagingMatches} from "../../store/actions/matchAction";
import {useNavigate} from "react-router-dom";

function MatchList() {
    const navigate = useNavigate();

    const { matches, lastIndex, activeQuarterResult } = useSelector(state => state.matchReducer);
    const { userInfo } = useSelector(state => state.loginUser);
    const dispatch = useDispatch();
    const [toggledId, setToggledId] = useState();
    const [activeTab, setActiveTab] = useState(0);
    const matchDetailButtons = ["종합", "1쿼터", "2쿼터", "3쿼터", "4쿼터", "5쿼터", "6쿼터"];

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const closeToggle = () => {
        setToggledId();
        setActiveTab(0);
    }

    const openToggle = (matchId) => {
        setToggledId(matchId);
        setActiveTab(0);
    }

    const updateButton = (match) => {
        if(match.notUpdatePlayerIds.includes(userInfo.member_id, 0) && userInfo.member_id !== null) {
            return (
                    <button className="toggle-button" onClick={() => navigate('/MatchResultWrite/'+match.matchId)}>
                        {"입력"}
                    </button>
            );
        }
    }

    const notUpdatedInfo = (match) => {
        if(match.notUpdatePlayerIds.includes(userInfo.member_id,0) && userInfo.member_id !== null) {
            return (
                <div className="matchDate">
                    {"결과 미입력 경기"}
                </div>
            );
        }
    }

    const mvp = (match) => {

            if(match.mvpId === -1) {
                return (
                    <div className="mvp">
                        <a target="_blank" className="mvpPlayer">
                            <img
                                className="mvpProfileImage"
                                src={""}
                            />
                            <span className="mvpJerseyNumber">{"-"}</span>
                        </a>
                        <span className="mvpName">{"MVP 미정"}</span>
                    </div>
                )
            } else {
                return (
                    <div className="mvp">
                        <a target="_blank" className="mvpPlayer">
                            <img
                                className="mvpProfileImage"
                                src={match.mvpProfileImg}
                                onClick={() => navigate('/player/'+match.mvpId)}
                            />
                            <span className="mvpJerseyNumber">{match.mvpJerseyNumber}</span>
                        </a>
                        <span className="mvpName">👑 {match.mvp}</span>
                    </div>
                )
            }

    }
    const detailTable = (activeTab, match, totalMatchResult) => {
        if(activeTab === 0) {
            return (
                <table className={"detailTable-Total-" + totalMatchResult}>
                    <colgroup>
                        <col/>
                        <col width={"10%"}/>
                        <col/>
                        <col/>
                        <col/>
                        <col/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th colspan="6">
                                <div className="head">
                                    <span style={{color: "white"}}>경기 기록</span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        match.playerNames.map((name, index) => {
                            if(name !== null) {
                                return (
                                  <tr>
                                      <td className="playerProfileImage">
                                          <div className="player">
                                              <a target="_blank" className="playerProfile">
                                                  <img
                                                      className="profileImage"
                                                      src={match.profileImgs[index]}
                                                      onClick={() => navigate('/player/' + match.playerIds[index])}
                                                  />
                                                  <span className="playerJerseyNumber">{match.playerJerseyNumbers[index]}</span>
                                              </a>
                                          </div>
                                      </td>
                                      <td className="name">
                                          <span className="name-span" onClick={() => navigate('/player/' + match.playerIds[index])}>{name}</span>
                                      </td>
                                      <td className="stats">
                                          <span className="goal-assist">{match.totalGoals[index] + "골 / " + match.totalAssists[index] + "어시"}</span>
                                      </td>
                                      <td className="rate">
                                          <div className="rate-col">
                                              {match.managerRatings[index] === -1 ? <span className="rate-grey">{"감독 평점 -"}</span>
                                                  :(match.managerRatings[index] <= 4 ? <span className="rate-grey">{"감독 평점 "+Number(match.managerRatings[index].toFixed(1)).toFixed(1)}</span>
                                                  : (match.managerRatings[index] > 4 && match.managerRatings[index] <=5 ? <span className="rate-green">{"감독 평점 "+Number(match.managerRatings[index].toFixed(1)).toFixed(1)}</span>
                                                      : (match.managerRatings[index] > 5 && match.managerRatings[index] <=6 ? <span className="rate-skyblue">{"감독 평점 "+Number(match.managerRatings[index].toFixed(1)).toFixed(1)}</span>
                                                          : (match.managerRatings[index] > 6 && match.managerRatings[index] <= 7 ? <span className="rate-blue">{"감독 평점 "+Number(match.managerRatings[index].toFixed(1)).toFixed(1)}</span>
                                                              : (match.managerRatings[index] > 7 ? <span className="rate-orange">{"감독 평점 "+Number(match.managerRatings[index].toFixed(1)).toFixed(1)}</span> : <span>{"감독 평점 "+Number(match.managerRatings[index].toFixed(1)).toFixed(1)}</span>)))))}
                                              {match.ratings[index] <= 4 ? <span className="rate-grey">{"팀원 평점 "+Number(match.ratings[index].toFixed(1))}</span>
                                                  : (match.ratings[index] > 4 && match.ratings[index] <=5 ? <span className="rate-green">{"팀원 평점 "+Number(match.ratings[index].toFixed(1)).toFixed(1)}</span>
                                                      : (match.ratings[index] > 5 && match.ratings[index] <=6 ? <span className="rate-skyblue">{"팀원 평점 "+Number(match.ratings[index].toFixed(1)).toFixed(1)}</span>
                                                          : (match.ratings[index] > 6 && match.ratings[index] <= 7 ? <span className="rate-blue">{"팀원 평점 "+Number(match.ratings[index].toFixed(1)).toFixed(1)}</span>
                                                              : (match.ratings[index] > 7 ? <span className="rate-orange">{"팀원 평점 "+Number(match.ratings[index].toFixed(1)).toFixed(1)}</span> : <span>{"팀원 평점 "+Number(match.ratings[index].toFixed(1)).toFixed(1)}</span>))))}
                                          </div>
                                      </td>
                                      <td className="position">
                                          <div className="position-div">
                                              <span className={"position-badge-"+match.mainPositions[index]}>{match.mainPositions[index]}</span>
                                          </div>
                                      </td>
                                      <td className="badges">
                                          <div className="badges-div">
                                              {match.mvpId === match.playerIds[index] ? <span className="mvp-badge">MOM</span> : <span></span>}
                                          </div>
                                      </td>
                                  </tr>
                                );
                            }
                        })
                    }
                    </tbody>
                </table>
            )
        }
        else {
            return (
                <table className={"detailTable-quarter-"+match.results[activeTab-1]}>
                    <colgroup>
                        <col/>
                        <col width={"10%"}/>
                        <col/>
                        <col/>
                        <col/>
                        <col/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th colSpan="6">
                                <div className="head">
                                    <span className={"quarterResult-"+match.results[activeTab-1]}>{match.results[activeTab-1] === 1 ? "승리" : (match.results[activeTab-1] === 0 ? "무승부" : "패배")}</span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        match.playerNames.map((name, index) => {
                            if(name !== null) {
                                return (
                                    <tr>
                                        <td className="playerProfileImage">
                                            <div className="player">
                                                <a target="_blank" className="playerProfile">
                                                    <img
                                                        className="profileImage"
                                                        src={match.profileImgs[index]}
                                                        onClick={() => navigate('/player/' + match.playerIds[index])}
                                                    />
                                                    <span className="playerJerseyNumber">{match.playerJerseyNumbers[index]}</span>
                                                </a>
                                            </div>
                                        </td>
                                        <td className="name">
                                            <span className="name-span" onClick={() => navigate('/player/' + match.playerIds[index])}>{name}</span>
                                        </td>
                                        <td className="stats">
                                            <span className="goal-assist">{match.pagingStats[index][activeTab-1].goals + "골 / " + match.pagingStats[index][activeTab-1].assist + "어시"}</span>
                                        </td>
                                        <td className="rate">
                                            <div className="rate-col">
                                                {match.managerRatings[index] === -1 ? <span className="rate-grey">{"감독 평점 -"}</span>
                                                    :(match.managerRatings[index] <= 4 ? <span className="rate-grey">{"감독 평점 "+Number(match.managerRatings[index].toFixed(1)).toFixed(1)}</span>
                                                        : (match.managerRatings[index] > 4 && match.managerRatings[index] <=5 ? <span className="rate-green">{"감독 평점 "+Number(match.managerRatings[index].toFixed(1)).toFixed(1)}</span>
                                                            : (match.managerRatings[index] > 5 && match.managerRatings[index] <=6 ? <span className="rate-skyblue">{"감독 평점 "+Number(match.managerRatings[index].toFixed(1)).toFixed(1)}</span>
                                                                : (match.managerRatings[index] > 6 && match.managerRatings[index] <= 7 ? <span className="rate-blue">{"감독 평점 "+Number(match.managerRatings[index].toFixed(1)).toFixed(1)}</span>
                                                                    : (match.managerRatings[index] > 7 ? <span className="rate-orange">{"감독 평점 "+Number(match.managerRatings[index].toFixed(1)).toFixed(1)}</span> : <span>{"감독 평점 "+Number(match.managerRatings[index].toFixed(1)).toFixed(1)}</span>)))))}
                                                {match.ratings[index] <= 4 ? <span className="rate-grey">{"팀원 평점 "+Number(match.ratings[index].toFixed(1))}</span>
                                                    : (match.ratings[index] > 4 && match.ratings[index] <=5 ? <span className="rate-green">{"팀원 평점 "+Number(match.ratings[index].toFixed(1)).toFixed(1)}</span>
                                                        : (match.ratings[index] > 5 && match.ratings[index] <=6 ? <span className="rate-skyblue">{"팀원 평점 "+Number(match.ratings[index].toFixed(1)).toFixed(1)}</span>
                                                            : (match.ratings[index] > 6 && match.ratings[index] <= 7 ? <span className="rate-blue">{"팀원 평점 "+Number(match.ratings[index].toFixed(1)).toFixed(1)}</span>
                                                                : (match.ratings[index] > 7 ? <span className="rate-orange">{"팀원 평점 "+Number(match.ratings[index].toFixed(1)).toFixed(1)}</span> : <span>{"팀원 평점 "+Number(match.ratings[index].toFixed(1)).toFixed(1)}</span>))))}
                                            </div>
                                        </td>
                                        <td className="position">
                                            <div className="position-div">
                                                <span className={"position-badge-"+POSITIONS.at(match.pagingStats[index][activeTab-1].positionIndex).position}>{POSITIONS.at(match.pagingStats[index][activeTab-1].positionIndex).position}</span>
                                            </div>
                                        </td>
                                        <td className="badges">
                                            <div className="badges-div">
                                                {match.mvpId === match.playerIds[index] ? <span className="mvp-badge">MOM</span> : <span></span>}
                                            </div>
                                        </td>
                                    </tr>
                                );
                            }
                        })
                    }
                    </tbody>
                </table>
            )
        }
    }
    const click = async (e) => {
        e.preventDefault();
        if(lastIndex === null) {
            //데이터가 없는 경우임.
            alert("매치 기록이 없어요 ㅠㅠ");
        }
        await axios.get("/api/match/read/"+lastIndex, config)
            .then((res) => {
                // console.log(res);
                if(res.data.empty === true) {
                    alert("더 이상 매치 기록이 없어요!");
                } else {
                    dispatch(pagingMatches(res.data.matchPagingData, res.data.lastIndex));
                    // console.log(matches);
                }
            }).catch((err) => {
                console.log(err);
            })
    }
    const matchDetail = (match, totalMatchResult) => {
        return (
            <div>
                <div className="matchDetail">
                    {
                        matchDetailButtons.map((btn, index)=>{
                            return (
                                <ul>
                                    <li>
                                        <button
                                            key={index}
                                            onClick={() => setActiveTab(index)}
                                            className={activeTab === index ? "matchDetail-button-Selected" : "matchDetail-button"}>
                                            {btn}
                                        </button>
                                    </li>
                                </ul>
                            );
                        })
                    }
                </div>
                <div className="quarterInfo">
                    {detailTable(activeTab, match, totalMatchResult)}
                </div>
            </div>

        )
    }

    return (
        <div className="contentWrap">
            <div className="tabItem__content">
                <div className="realContent">
                    <div className="matchListContainer">
                        <div className="content">
                            <div className="matchItemList">
                                {/*여기서부터 한 매치 - matches를 돌면서 렌더링*/}
                                {matches !== null ? matches.map((match) => {
                                    const arr1 = match.date.split('T')
                                    const dateString = arr1[0];
                                    const day = NumberToDayOfWeekConverter(new Date(dateString).getDay());
                                    const timeString = arr1[1];
                                    const arr2 = dateString.split('-');
                                    const year = arr2[0];
                                    const month = arr2[1];
                                    const date = arr2[2];
                                    const arr3 = timeString.split(':');
                                    const hour = arr3[0];
                                    const min = arr3[1];
                                    const stadiumName = STADIUMS.at(match.stadiumIndex).name;
                                    const playerSize = match.playerSize;

                                    let idx = 0;
                                    let winCount = 0; let drawCount = 0; let loseCount = 0;
                                    match.results.map((result) => {
                                        if(result === 1) {
                                            winCount++;
                                        } else if(result === 0) {
                                            drawCount++;
                                        } else {
                                            loseCount++;
                                        }
                                    });
                                    let winRate = Number(winCount/6 * 100).toFixed(1);

                                    // const totalResult = Math.max(winCount, drawCount, loseCount);
                                    let totalMatchResult;
                                    if(winCount >= 3) {
                                        totalMatchResult = "Win";
                                    } else if(winCount === 2) {
                                        if(drawCount >= loseCount) totalMatchResult = "Draw";
                                        else totalMatchResult = "Lose";
                                    } else if(winCount === 1) {
                                        if(drawCount >= loseCount) totalMatchResult = "Draw";
                                        else totalMatchResult = "Lose";
                                    }

                                    // switch (totalResult) {
                                    //     case winCount:{
                                    //         totalMatchResult = "Win";
                                    //         break;
                                    //     }
                                    //     case drawCount: {
                                    //         totalMatchResult = "Draw";
                                    //         break;
                                    //     }
                                    //     case loseCount: {
                                    //         totalMatchResult = "Lose";
                                    //         break;
                                    //     }
                                    // }

                                    return (
                                        /*매치 1개씩 렌더링*/
                                        <div className="matchItemWrap" key={match.matchId}>
                                            <div
                                                className={"matchItem"+totalMatchResult+"Extended"}
                                                data-match-result={totalMatchResult}
                                            >
                                                <div className={"deco"+totalMatchResult}></div>
                                                <div className="match-content">
                                                    <div className={"matchHeader"+totalMatchResult}>
                                                        <div className="head-group">
                                                            <div className="matchType">
                                                                {match.num+" vs "+match.num}
                                                            </div>
                                                        </div>
                                                        <div className="head-group">
                                                            {notUpdatedInfo(match)}
                                                            <div className="matchDate">
                                                                {year}-{month}-{date} {day} {hour}:{min}
                                                            </div>
                                                            {updateButton(match)}
                                                            <button className="toggle-button" onClick={()=> toggledId === match.matchId ? closeToggle() : openToggle(match.matchId)}>
                                                                {toggledId === match.matchId ? "-" : "+"}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className={"matchBody"}>
                                                        <div className="main">
                                                            {/*<div className="mvp">*/}
                                                            {/*    <a target="_blank" className="mvpPlayer">*/}
                                                            {/*        <img*/}
                                                            {/*            className="mvpProfileImage"*/}
                                                            {/*            src={match.mvpProfileImg}*/}
                                                            {/*            onClick={() => navigate('/player/'+match.mvpId)}*/}
                                                            {/*        />*/}
                                                            {/*        <span className="mvpJerseyNumber">{match.mvpJerseyNumber}</span>*/}
                                                            {/*    </a>*/}
                                                            {/*    <span className="mvpName">👑 {match.mvp}</span>*/}
                                                            {/*</div>*/}
                                                            {mvp(match)}
                                                            <div className="wdl-stats">
                                                                <div className="stadiumName">
                                                                    {stadiumName}
                                                                </div>
                                                                <div className="wdl">
                                                                    <span className="win">{winCount}</span>
                                                                    {" / "}
                                                                    <span className="draw">{drawCount}</span>
                                                                    {" / "}
                                                                    <span className="lose">{loseCount}</span>
                                                                </div>
                                                                <div className="wdl-rate">
                                                                    {+ winRate + "% 승률"}
                                                                </div>
                                                                <div className="wdl-badges">
                                                                    {match.results.map((result, index) => {
                                                                        if(result === 1) {
                                                                            return(<span key={index} className="wMark">W</span>);
                                                                        } else if(result === 0) {
                                                                            return(<span key={index} className="dMark">D</span>);
                                                                        } else {
                                                                            return(<span key={index} className="lMark">L</span>);
                                                                        }
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="sub">
                                                            <div className="players">
                                                                {
                                                                    match.profileImgs.map((img, index) => {
                                                                        if(img !== null) {
                                                                            return(
                                                                                <div className="player" key={index}>
                                                                                    <img
                                                                                        className="playerProfileImage"
                                                                                        src={img}
                                                                                        onClick={() => navigate('/player/'+match.playerIds.at(index))}
                                                                                    />
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            return(
                                                                              <dd className="player" key={index}></dd>
                                                                            );
                                                                        }
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<div className="matchDetail">*/}
                                            {/*    {*/}
                                            {/*        matchDetailButtons.map((btn, index)=>{*/}
                                            {/*            return (*/}
                                            {/*                <ul>*/}
                                            {/*                    <li>*/}
                                            {/*                        <button*/}
                                            {/*                            key={index}*/}
                                            {/*                            onClick={() => setActiveTab(index)}*/}
                                            {/*                            className={activeTab === index ? "matchDetail-button-Selected" : "matchDetail-button"}>*/}
                                            {/*                            {btn}*/}
                                            {/*                        </button>*/}
                                            {/*                    </li>*/}
                                            {/*                </ul>*/}
                                            {/*            );*/}
                                            {/*        })*/}
                                            {/*    }*/}
                                            {/*    <div className="quarterInfo">*/}

                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className="detailInfo">
                                                {toggledId === match.matchId ? matchDetail(match, totalMatchResult) : ""}
                                            </div>

                                        </div>
                                    )
                                }) : <Accordion.Item key={0} eventKey={0}>
                                    <Accordion.Header>
                                        <Stack gap={3}>
                                            <div>데이터가 없습니다 ㅠㅠ</div>
                                        </Stack>
                                    </Accordion.Header>
                                </Accordion.Item>}
                            </div>
                            <button className="more" onClick={click}>더 보기</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MatchList;