import {Button, Container, Form, Nav, Navbar} from "react-bootstrap";
import "./Header.css";
import {RxAvatar} from "react-icons/rx";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {doLogout} from "../../store/actions/loginUserAction";
import {light} from "@mui/material/styles/createPalette";
function Header() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.loginUser)
    const KakaoLogoutURL = `https://kauth.kakao.com/oauth/logout?client_id=${process.env["REACT_APP_KAKAO-CLIENT-ID"]}&logout_redirect_uri=${process.env["REACT_APP_LOGOUT-REDIRECT-URI"]}`;

    const myPage = () => {
        if(userInfo.member_id !== null) {
            navigate('/player/'+userInfo.member_id);
        } else {
            navigate('/');
        }
    }
    const home = () => {
        navigate('/main');
        window.location.reload();
    }
    const players = () => {
        navigate('/players');
    }
    const records = () => {
        navigate('/records');
    }
    const logout = () => {
        // dispatch(setLogin(false));
        // debugger;
        dispatch(doLogout());
        alert("로그아웃");
    }
    const logoutButton = () => {
        if(userInfo.member_id !== null) {
            return  <Nav.Link href={KakaoLogoutURL} onClick={logout} style={{color:"white"}}>로그아웃 </Nav.Link>;
        }
    }
    /*
    collapseOnSelect는 Nav.Link에 eventKey가 있어야 작동함.
     */
    if (window.location.pathname === '/') return null;

    return (
        <header>
            {/*<Navbar collapseOnSelect={true} expand="lg" className="bg-body-tertiary" data-bs-theme="dark" bg="dark" >*/}
            <Navbar className="jwgio-Navbar" collapseOnSelect={true} expand="lg" style={{}}>
                <Container style={{fontFamily: "jwgio-main-font"}}>
                    <Navbar.Brand onClick={home}><Nav.Link eventKey={0} style={{color:"white"}}>FC 정우지오</Nav.Link></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link eventKey={1} onClick={players} style={{color:"white"}}>선수</Nav.Link>
                            {/*<Nav.Link href="#link">경기</Nav.Link>*/}
                            <Nav.Link eventKey={2} onClick={records} style={{color:"white"}}>기록실</Nav.Link>
                            {logoutButton()}
                            {/*<Nav.Link href="/player">마이 페이지</Nav.Link>*/}
                            {/*<NavDropdown title="Dropdown" id="basic-nav-dropdown">*/}
                            {/*    <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>*/}
                            {/*    <NavDropdown.Item href="#action/3.2">*/}
                            {/*        Another action*/}
                            {/*    </NavDropdown.Item>*/}
                            {/*    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>*/}
                            {/*    <NavDropdown.Divider />*/}
                            {/*    <NavDropdown.Item href="#action/3.4">*/}
                            {/*        Separated link*/}
                            {/*    </NavDropdown.Item>*/}
                            {/*</NavDropdown>*/}
                        </Nav>
                        {/*<Form className="d-flex">*/}
                        {/*    <Form.Control*/}
                        {/*        type="search"*/}
                        {/*        placeholder="검색"*/}
                        {/*        className="me-2"*/}
                        {/*        aria-label="Search"*/}
                        {/*    />*/}
                        {/*    <Button variant="outline-success">Search</Button>*/}
                        {/*</Form>*/}
                        <div>
                            <Nav.Link eventKey={3} style={{color:"white"}}><RxAvatar size="27" color="white" onClick={myPage}/></Nav.Link>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

export default Header;