export const STADIUMS = [
    { index: 0, code: "yt-nc", name: "야탑 NC백화점 스카이필드", number: 5},
    { index: 1, code: "mr", name: "모란 풋살장", number: 5},
    { index: 2, code: "sw-plab-in", name: "플랩 스타디움 수원 실내", number: 5},
    { index: 3, code: "bd-kj1", name: "성남 분당 킹주니어 스포츠 클럽 1호점", number: 6},
    { index: 4, code: "bd-kj2", name: "성남 분당 킹주니어 스포츠 클럽 2호점", number: 6},
    { index: 5, code: "sw-plab-3", name: "플랩 스타디움 수원 3구장", number: 5},

]

export const POSITIONS = [
    { index: 0, code: "LA", name: "LEFT ALA (LM)", position: "LEFT ALA"},
    { index: 1, code: "RA", name: "RIGHT ALA (RM)", position: "RIGHT ALA"},
    { index: 2, code: "PV", name: "PIVOT (ST)", position: "PIVOT"},
    { index: 3, code: "FX", name: "FIXO (CB)", position: "FIXO"},
    { index: 4, code: "GO", name: "GOLEIRO (GK)", position: "GOLEIRO"},
]