import {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {Button, ModalBody, ModalHeader, ModalTitle} from "react-bootstrap";
const InstallPrompt = () => {

    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isIOS, setIsIOS] = useState(false);
    const [show, setShow] = useState(true);
    const [ isStandAlone, setIsStandAlone] = useState(null);
    const appleExpression = /Apple/i;
    const safariExpression = /Safari/i;
    const isAppleSafari = () => {
        return appleExpression.test(navigator.vendor) &&
            safariExpression.test(navigator.userAgent);
    };

    function isRunningStandalone() {
        return (window.matchMedia('(display-mode: standalone)').matches);
    }

    useEffect(() => {
        const isDeviceIOS = isAppleSafari();
        setIsIOS(isDeviceIOS);
        const isStandAlone = isRunningStandalone();
        setIsStandAlone(isStandAlone);
        // if(navigator.userAgent.match(/SAMSUNG|Samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i)) {
        //     console.log("it's Samsung");
        //     // your code for Samsung Smartphones goes here...
        // }

        // document.getElementById('myButton').addEventListener('click', function() {
        //     // Check if push notifications are supported and allowed
        //     if (navigator.serviceWorker && window.PushManager && window.Notification) {
        //         // Request permission to send push notifications
        //         navigator.serviceWorker.getRegistration().then(function(registration) {
        //             registration.pushManager.subscribe({ userVisibleOnly: true }).then(function(subscription) {
        //                 console.log('Push notifications are allowed.');
        //                 //save the push subscription in your database
        //             }).catch(function(error) {
        //                 console.log('Error:', error);
        //             });
        //         });
        //     }
        // });

        const handler = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
            setShow(true);
        };
        // beforeinstallprompt에 이벤트 핸들러를 등록합니다.
        window.addEventListener('beforeinstallprompt', handler);
        return () => {
            console.log("install prompt!");
            window.removeEventListener('beforeinstallprompt', handler);
        };
    }, []);


    const installApp = () => {
        // 설치 메서드 실행
        deferredPrompt?.prompt();
        deferredPrompt?.userChoice.then(choiceResult => {
            clearPrompt();
        });
    };

    const clearPrompt = () => {
        setDeferredPrompt(null);
        setShow(false);
    };

    const confirm = () => {
        // if (navigator.serviceWorker && window.safari.pushNotification) {
        //     // Request permission to send push notifications
        //     navigator.serviceWorker.getRegistration().then(function(registration) {
        //         registration.pushManager.subscribe({ userVisibleOnly: true }).then(function(subscription) {
        //             console.log('Push notifications are allowed.');
        //             //save the push subscription in your database
        //         }).catch(function(error) {
        //             console.log('Error:', error);
        //         });
        //     });
        // }
    }

    async function requestPermission() {
        setShow(false);
        return new Promise((resolve) => {

            const timeoutId = setInterval(() => {
                if (Notification.permission === 'granted') {
                    handleComplete(Notification.permission);
                }
            }, 3000);

            const handleComplete = (permission) => {
                clearInterval(timeoutId);
                resolve(permission);
            };

            Notification.requestPermission(handleComplete)?.then?.(handleComplete);
            console.log("requestPermission()");
        });
    }

    return { deferredPrompt, installApp, clearPrompt, isIOS, show, setShow, isStandAlone, confirm, requestPermission };
}


export default function A2HS() {
    const { deferredPrompt, installApp, clearPrompt, isIOS, show, isStandAlone, confirm, requestPermission} = InstallPrompt();

    // if(isIOS && isStandAlone) { //ios환경 + standAlone 경우.
    //     return (
    //         <Modal show={show} >
    //             <Modal.Dialog>
    //                 <Modal.Body style={{textAlign: "center", fontFamily: "jwgio-main-font"}}>
    //                     <h5>FC 정우지오 알림을 받아주세요!</h5>
    //                 </Modal.Body>
    //                 <Modal.Footer style={{justifyContent: "center", display: "flex", alignItems: "center"}}>
    //                     <Button id={"myButton"} variant="secondary" size={"sm"} onClick={requestPermission}>확인</Button>
    //                 </Modal.Footer>
    //             </Modal.Dialog>
    //         </Modal>);
    // }

    if(isIOS && !isStandAlone) { //ios환경 + standAlone이 아닌 경우.
        return (
            <Modal show={show} >
                <Modal.Dialog>
                    <Modal.Body style={{textAlign: "center", fontFamily: "jwgio-main-font"}}>
                        <h5>safari 아래의 <img src={"/safari-share-icon.png"}/>를 클릭하여</h5>
                        <h5>홈 화면에 추가해주세요!</h5>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: "center", display: "flex", alignItems: "center"}}>
                        <Button id={"myButton"} variant="secondary" size={"sm"} onClick={requestPermission}>확인</Button>
                    </Modal.Footer>
                </Modal.Dialog>
            </Modal>);
    }
    return deferredPrompt ? (
        <Modal show={show}>
            <Modal.Dialog>
                <Modal.Body style={{textAlign: "center", fontFamily: "jwgio-main-font"}}>
                    <h5>FC 정우지오 바로가기를 설치해주세요.</h5>
                    <h5>더욱 원활하게 이용할 수 있어요!</h5>
                </Modal.Body>
                <Modal.Footer style={{justifyContent: "center", display: "flex", alignItems: "center"}}>
                    <Button variant="primary" size={"sm"} onClick={installApp}>추가하기</Button>
                    <Button variant="secondary" size={"sm"} onClick={clearPrompt}>취소</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>

    ) : null;
}