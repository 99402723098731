import React from "react";
import "./Footer.css";
const footer = () => {
    return (
        <footer className="footer">
            <div className="contents">
                {/*<hr />*/}
                <h1 className="title">FC 정우지오</h1>
            </div>
        </footer>
    )
}

export default footer;