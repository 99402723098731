import React, { useState } from 'react'
import {Rating} from 'react-simple-star-rating';
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import axios from "axios";
import {useSelector} from "react-redux";

function MatchPlayerRating(props) {
    const [ rate, setRate] = useState(0);
    const [ updated, setUpdated ] = useState(props.updated);
    const { userInfo } = useSelector(state => state.loginUser);
    const matchId = props.matchId;
    const username = props.username;
    const memberId = props.memberId;
    const handleRating = (rate) => {
        setRate(rate)
    }
    // Optinal callback functions
    // const onPointerEnter = () => console.log('Enter');
    // const onPointerLeave = () => console.log('Leave');
    const onPointerMove = (value, index) => console.log(value, index);

    const click = async (e) => {
        e.preventDefault();
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        await axios.put("/api/match/writePlayerRating/"+matchId+"/"+memberId+"/"+rate, config)
            .then((res) => {
                setRate(res.data.rate);
                setUpdated(res.data.updated);
            }).catch((err) => {
                console.log(err);
            })
    }
    const rating = () => {
        if(updated === true) {
            return <div>평점 업데이트 완료</div>
        } else {
            return (
                <Rating
                    onClick={handleRating}
                    // onPointerEnter={onPointerEnter}
                    // onPointerLeave={onPointerLeave}
                    onPointerMove={onPointerMove}
                    initialValue={rate}
                    allowFraction={true}
                    transition={true}
                    showTooltip={false}
                    iconsCount={10}
                    readonly={updated}
                    tooltipDefaultText={"0"}
                    size={33}
                ></Rating>
            )
        }
    }
    return(

        <Card style={{ width: "auto", alignItems: "center" }}>
            <Card.Body>
                <Card.Title>{username}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                {/*<Rating*/}
                {/*    onClick={handleRating}*/}
                {/*    // onPointerEnter={onPointerEnter}*/}
                {/*    // onPointerLeave={onPointerLeave}*/}
                {/*    onPointerMove={onPointerMove}*/}
                {/*    initialValue={rate}*/}
                {/*    allowFraction={true}*/}
                {/*    transition={true}*/}
                {/*    showTooltip={false}*/}
                {/*    iconsCount={10}*/}
                {/*    readonly={updated}*/}
                {/*    tooltipDefaultText={"0"}*/}
                {/*    size={33}*/}
                {/*></Rating>*/}
                {rating()}
                <div style={{ width: "auto", alignItems: "center", display: "flex", justifyContent: "center"}}>
                    <Button style={{marginLeft: "5px", marginTop: "5px"}} disabled={updated} onClick={click}>등록</Button>
                </div>
            </Card.Body>
        </Card>
    )
}

export default MatchPlayerRating;