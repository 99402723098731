import Card from "react-bootstrap/Card";
import {useState} from "react";

function PlayerStatsTable(props) {
    const [stats, setState] = useState(props.stats);

    return(
        <table className="table table-hover text-nowrap" style={{width: "100%", padding:"auto"}}>
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">매치</th>
                    <th scope="col">골</th>
                    <th scope="col">어시</th>
                    <th scope="col">평점(감독)</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th scope="row">전체</th>
                    <td>{stats.matchCount}</td>
                    <td>{stats.totalGoal}</td>
                    <td>{stats.totalAssist}</td>
                    <td>{Number(stats.rate).toFixed(1)}({Number(stats.managerRate).toFixed(1)})</td>
                </tr>
            </tbody>
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">쿼터</th>
                    <th scope="col">골</th>
                    <th scope="col">어시</th>
                    <th scope="col">승/무/패</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th scope="row">PIVOT</th>
                    <td>
                        <span className="text-danger">
                          <i className="fas fa-caret-down me-1"></i><span>{Number(stats.pivot_Win)+Number(stats.pivot_Draw)+Number(stats.pivot_Lose)}</span>
                        </span>
                    </td>
                    <td>
                        <span className="text-success">
                          <i className="fas fa-caret-up me-1"></i><span>{stats.pivot_Goal}</span>
                        </span>
                    </td>
                    <td>
                        <span className="text-success">
                          <i className="fas fa-caret-up me-1"></i><span>{stats.pivot_Assist}</span>
                        </span>
                    </td>
                    <td>
                        <span className="text-success">
                          <i className="fas fa-caret-up me-1"></i><span>{stats.pivot_Win}/{stats.pivot_Draw}/{stats.pivot_Lose}</span>
                        </span>
                    </td>
                </tr>
                <tr>
                    <th scope="row">LEFT ALA</th>
                    <td>
                        <span className="text-danger">
                          <i className="fas fa-caret-down me-1"></i><span>{Number(stats.left_ala_Win)+Number(stats.left_ala_Draw)+Number(stats.left_ala_Lose)}</span>
                        </span>
                    </td>
                    <td>
                        <span className="text-success">
                          <i className="fas fa-caret-up me-1"></i><span>{stats.left_ala_Goal}</span>
                        </span>
                    </td>
                    <td>
                        <span className="text-success">
                          <i className="fas fa-caret-up me-1"></i><span>{stats.left_ala_Assist}</span>
                        </span>
                    </td>
                    <td>
                        <span className="text-success">
                          <i className="fas fa-caret-up me-1"></i><span>{stats.left_ala_Win}/{stats.left_ala_Draw}/{stats.left_ala_Lose}</span>
                        </span>
                    </td>
                </tr>
                <tr>
                    <th scope="row">RIGHT ALA</th>
                    <td>
                        <span className="text-danger">
                          <i className="fas fa-caret-down me-1"></i><span>{Number(stats.right_ala_Win)+Number(stats.right_ala_Draw)+Number(stats.right_ala_Lose)}</span>
                        </span>
                    </td>
                    <td>
                        <span className="text-success">
                          <i className="fas fa-caret-up me-1"></i><span>{stats.right_ala_Goal}</span>
                        </span>
                    </td>
                    <td>
                        <span className="text-success">
                          <i className="fas fa-caret-up me-1"></i><span>{stats.right_ala_Assist}</span>
                        </span>
                    </td>
                    <td>
                        <span className="text-success">
                          <i className="fas fa-caret-up me-1"></i><span>{stats.right_ala_Win}/{stats.right_ala_Draw}/{stats.right_ala_Lose}</span>
                        </span>
                    </td>
                </tr>
                <tr>
                    <th scope="row">FIXO</th>
                    <td>
                        <span className="text-danger">
                          <i className="fas fa-caret-down me-1"></i><span>{Number(stats.fixo_Win)+Number(stats.fixo_Draw)+Number(stats.fixo_Lose)}</span>
                        </span>
                    </td>
                    <td>
                        <span className="text-success">
                          <i className="fas fa-caret-up me-1"></i><span>{stats.fixo_Goal}</span>
                        </span>
                    </td>
                    <td>
                        <span className="text-success">
                          <i className="fas fa-caret-up me-1"></i><span>{stats.fixo_Assist}</span>
                        </span>
                    </td>
                    <td>
                        <span className="text-success">
                          <i className="fas fa-caret-up me-1"></i><span>{stats.fixo_Win}/{stats.fixo_Draw}/{stats.fixo_Lose}</span>
                        </span>
                    </td>
                </tr>

            </tbody>
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">쿼터</th>
                    <th scope="col">클린시트</th>
                    <th scope="col">{" "}</th>
                    <th scope="col">승/무/패</th>
                </tr>
            </thead>
            <tbody>
            <tr>
                <th scope="row">GOLEIRO</th>
                <td>
                        <span className="text-danger">
                          <i className="fas fa-caret-down me-1"></i><span>{Number(stats.goleiro_Win)+Number(stats.goleiro_Draw)+Number(stats.goleiro_Lose)}</span>
                        </span>
                </td>
                <td>
                        <span className="text-success">
                          <i className="fas fa-caret-up me-1"></i><span>{stats.goleiro_cs}</span>
                        </span>
                </td>
                <td>
                        <span className="text-success">
                          <i className="fas fa-caret-up me-1"></i><span>{" "}</span>
                        </span>
                </td>
                <td>
                        <span className="text-success">
                          <i className="fas fa-caret-up me-1"></i><span>{stats.goleiro_Win}/{stats.goleiro_Draw}/{stats.goleiro_Lose}</span>
                        </span>
                </td>
            </tr>
            </tbody>
        </table>
    );
}

export default PlayerStatsTable;