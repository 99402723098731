import React, {useEffect, useState} from 'react';
import { MDBCard, MDBCardText, MDBCardBody, MDBBtn, MDBTypography } from 'mdb-react-ui-kit';
import MatchAccordion from "../components/match/MatchAccordion";
import PlayerStatsTable from "../components/player/PlayerStatsTable";
import ModalLayout, {playerInfoEditModal} from "../components/layout/ModalLayout";
import PlayerInfoEditModalBody from "../components/player/PlayerInfoEditModalBody";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {POSITIONS} from "../components/dropdown/DropdownData";
import {setProfile, updateMyPage} from "../store/actions/userAction";
import {useNavigate, useParams} from "react-router-dom";
import jerseyImg from "../assets/img/jersey-back.png";
import "./Player.css";

function Player() {
    const [modalShow, setModalShow] = useState(false);
    const dispatch = useDispatch();
    const { myPage } = useSelector(state => state.userReducer);
    const { userInfo } = useSelector(state => state.loginUser);
    const { memberId } = useParams();
    const navigate = useNavigate();
    const [ stats, setStats ] = useState();
    const [ playerStatsTable , setPlayerStatsTable ] = useState();
    const [ playerStatsCard, setPlayerStatsCard ] = useState();
    useEffect( () => {
        async function fetchData() {

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                params: {
                    member_id: memberId
                }
            };
            await axios.get("/api/member/profile/read", config)
                .then((res) => {
                    let myPage =
                        {
                            name: res.data.name,
                            imgUrl: res.data.imgUrl,
                            jersey_number: res.data.number,
                            position: POSITIONS.at(Number(res.data.positionIndex))
                        };
                    let profile =
                        {
                            name: res.data.name,
                            imgUrl: res.data.imgUrl,
                            jersey_number: res.data.number,
                            position: Number(res.data.positionIndex)
                        };
                    setStats(res.data);
                    renderPlayerStatsTable(res.data);
                    renderPlayerStatsCard(res.data);
                    dispatch(setProfile(profile));
                    dispatch(updateMyPage(myPage));
                }).catch((err) => {
                    console.log(err);
                })
        }
        fetchData();
    }, []);

    const click = () => {
        setModalShow(true);
    }
    const profileEditBtn = () => {
        return Number(memberId) === userInfo.member_id
            ? <MDBBtn rounded size="sm" onClick={click}>
            프로필 수정
            </MDBBtn>
            : null;
    }
    const renderPlayerStatsTable = (stats) => {
        setPlayerStatsTable(<PlayerStatsTable stats={stats}/>);
    }
    const renderPlayerStatsCard = (stats) => {
        setPlayerStatsCard(
            <MDBCardText className="text-muted mb-4">
                {stats.totalWin} 승 <span className="mx-2">|</span> {stats.totalDraw} 무 <span className="mx-2">|</span> {stats.totalLose} 패 <span className="mx-2">|</span>{stats.mvpCount} MVP
            </MDBCardText>
        );
    }
    return (
        <div  style={{ backgroundColor: 'lightgray'}}>
            <MDBCard style={{ borderRadius: '15px', backgroundColor: 'lightgray'}}>
                <MDBCardBody className="text-center">
                    <div className="mt-3 mb-4" style={{justifyContent: "center", display: "flex", alignItems: "center"}}>
                        <div style={{
                            background: "lightblue",
                            width:"150px",
                            height:"150px",
                            borderRadius: "50%",
                            overflow: "hidden",
                            position: "inherit",
                            borderStyle: "solid",
                            borderColor: "black",
                            marginBottom: "1%"
                        }}>
                            <img
                                src={myPage.imgUrl ? myPage.imgUrl :`/icon-profile-cr7.png`}
                                alt="프로필 이미지"
                                style={{width:"100%", height: "100%", objectFit: "cover"}}
                            />
                        </div>
                    </div>
                    <div className="jersey_image">
                        <img src={jerseyImg} />
                        <h2 className="jersey_name">{myPage.name}</h2>
                        <h1 className={`jersey_number_${(Number(myPage.jersey_number).length === 1) ? "one" : "two"}`}>{myPage.jersey_number}</h1>
                    </div>
                    <MDBTypography tag="h4">{myPage.name}</MDBTypography>
                    <MDBCardText className="text-muted mb-4">
                        No.{myPage.jersey_number} <span className="mx-2">|</span> {myPage.position ? myPage.position.name : null}
                    </MDBCardText>
                    {playerStatsCard}
                    {profileEditBtn()}
                    <div className="d-flex justify-content-between text-center mt-5 mb-2"
                    style={{alignItems:"center", justifyContent:"center"}}>
                        {playerStatsTable}
                    </div>
                </MDBCardBody>
            </MDBCard>
            <ModalLayout show={modalShow} onHide={() => setModalShow(false)} type={playerInfoEditModal} children={<PlayerInfoEditModalBody/>}/>
        </div>

    );
}

export default Player;