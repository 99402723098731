import * as React from 'react';
import AvatarMui from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Badge from 'react-bootstrap/Badge';
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

function Avatar (props) {
    const use = props.use;
    const data = props.data;
    const navigate = useNavigate();
    const click = () => {
        navigate('/player/'+data.playerId);
    }
    if(use === true) {
        return (
            <Stack spacing={0} >
                <AvatarMui
                    alt="Remy Sharp"
                    src={data.img}
                    sx={{ width: "50px", height: "50px" }}
                    onClick={click}
                />
                {/*<Badge pill bg="primary" style={{fontSize: "70%"}}>*/}
                {/*    /!*{props.name}*!/*/}
                {/*    김철수*/}
                {/*</Badge>*/}
                <Badge pill bg="warning" text="dark" >
                    {data.managerRatings !== -1 ? ((Number(data.managerRatings) + Number(data.ratings))/2).toFixed(1) : Number(data.ratings).toFixed(1)}
                </Badge>
                <Badge pill bg="danger" text="white" >
                    {data.goal} ⚽
                </Badge>
                <Badge pill bg="primary" text="red">
                    {data.assist} 🅰️
                </Badge>
            </Stack>

        )
    } else {
        return (
            <div/>
        )
    }

}
export default Avatar;