import "./KakaoLoginButton.css";
import {Button} from "react-bootstrap";
import KakaoSymbol from "../../assets/img/kakao-symbol.png";
import {useNavigate} from "react-router-dom";
import KakaoLoginButtonImg from "../../assets/img/kakao_login_medium_wide.png";
import React, {useEffect, useRef, useState} from "react";
function KakaoLoginButton() {
    const KakaoAuthURI = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env["REACT_APP_KAKAO-CLIENT-ID"]}&redirect_uri=${process.env["REACT_APP_LOGIN-REDIRECT-URI"]}&response_type=code`;
    const navigate = useNavigate();
    const btnRef = useRef();
    const [isIOS, setIsIOS] = useState(false);
    const [ isStandAlone, setIsStandAlone] = useState(null);

    const appleExpression = /Apple/i;
    const safariExpression = /Safari/i;
    const isAppleSafari = () => {
        return appleExpression.test(navigator.vendor) &&
            safariExpression.test(navigator.userAgent);
    };
    function isRunningStandalone() {
        return (window.matchMedia('(display-mode: standalone)').matches);
    }
    const click = e => {

        if(isIOS && isStandAlone) {
            requestPermission();
        }
        btnRef.current.click();
    }

    async function requestPermission() {
        return new Promise((resolve) => {

            const timeoutId = setInterval(() => {
                if (Notification.permission === 'granted') {
                    handleComplete(Notification.permission);
                }
            }, 3000);

            const handleComplete = (permission) => {
                clearInterval(timeoutId);
                resolve(permission);
            };

            Notification.requestPermission(handleComplete)?.then?.(handleComplete);
            console.log("requestPermission()");
        });
    }

    useEffect(() => {
        const isDeviceIOS = isAppleSafari();
        setIsIOS(isDeviceIOS);
        const isStandAlone = isRunningStandalone();
        setIsStandAlone(isStandAlone);

    }, []);
    return (

        <div className="kakao-login-btn" onClick={click}>
            <a className="kakao-login-href" ref={btnRef} href={KakaoAuthURI} />
            <div className="kakao-login-btn-symbol-div">
                <img className="kakao-login-btn-symbol" src={KakaoSymbol}/>
            </div>
            <div className="kakao-login-btn-label-div">
                정우지오 with kakao
            </div>
        </div>
    )
}

export default KakaoLoginButton;