import {DropdownButton, Dropdown} from "react-bootstrap";
import {useEffect, useState} from "react";
import "./CustomDropdown.css";
import {positionDropdown, resultPositionDropdown, stadiumDropdown} from "../layout/ModalLayout";
import {POSITIONS, STADIUMS} from "./DropdownData";
import {useDispatch, useSelector} from "react-redux";
import {setProfile} from "../../store/actions/userAction";
import {registerMatchStadiumCode} from "../../store/actions/matchAction";
function CustomDropdown(props) {
    const title = props.title;
    const dispatch = useDispatch();
    const { profile, myPage } = useSelector(state => state.userReducer);
    const {matchRegister} = useSelector(state => state.matchReducer);
    const [ selectedItem, setSelectedItem ] = useState(() => {
         if(props.type === stadiumDropdown) return title;
         else if(props.type === resultPositionDropdown) {
             if(props.positionIndex === 5) return title;
             else {
                 const at = POSITIONS.at(props.positionIndex);
                 return at.name;
             }
         }
         else return myPage.position === null ? title : myPage.position.name;
    });

    const SelectItem = (eventKey, e) => {
        const idx = e.target.id;
        if(props.type === stadiumDropdown) {
            const at = STADIUMS.at(idx);
            setSelectedItem(at.name);
            console.log(eventKey);
            console.log(e.target.id);
            dispatch(registerMatchStadiumCode(eventKey));
            console.log(matchRegister);
        }
        else if(props.type === positionDropdown) {
            const at = POSITIONS.at(idx);
            setSelectedItem(at.name);
            let temp = {name: profile.name, img: profile.img, jersey_number: profile.jersey_number, position: Number(eventKey) }
            dispatch(setProfile(temp));
        }
        else if(props.type === resultPositionDropdown) {
            const at = POSITIONS.at(idx);
            setSelectedItem(at.name);
            let temp = {name: profile.name, img: profile.img, jersey_number: profile.jersey_number, position: Number(eventKey) }
        }

    }

    const dropdownItem = () => {
        if(props.type === stadiumDropdown) {
            return (
                STADIUMS.map((stadium) => (
                    <Dropdown.Item eventKey={stadium.code} key={stadium.index} id={stadium.index}>{stadium.name}</Dropdown.Item>
                ))
            )
        }
        else if(props.type === positionDropdown || resultPositionDropdown) {
            return (
                POSITIONS.map((position) => (
                    <Dropdown.Item  eventKey={position.index} key={position.index} id={position.index}>{position.name}</Dropdown.Item>
                ))
            )
        }
    }

    return(
        <div id="dropdown-wrap-div">
            <DropdownButton
                id="dropdown-basic-button"
                title={selectedItem}
                onSelect={(eventKey, e) => SelectItem(eventKey, e)}
                >
                {dropdownItem()}
            </DropdownButton>
        </div>
    )
}

export default CustomDropdown;