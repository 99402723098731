const initialState = {
    mode:'welcome',
    selected_content_id:2,
    subject:{title:'WEB', sub:'world wide web!'},
    contents:[
        {id:1, title:'html', desc:'HTML is for information'},
        {id:2, title:'CSS', desc:'CSS is for design'},
        {id:3, title:'JavaScript', desc:'JavaScript is for interactive'}
    ],
    max_content_id:3
}
export const MODE_CHANGE = "MODE_CHANGE";
export const SELECTED_CONTENT_CHANGE = "SELECTED_CONTENT_CHANGE";
export const SET_SUBJECT = "SET_SUBJECT";
export const CREATE_CONTENT = "CREATE_CONTENT";
export const UPDATE_CONTENT = "UPDATE_CONTENT";
function reducer(state = initialState, action) {
    switch (action.type) {
        case MODE_CHANGE:
            if(isNaN(action.id)) {
                console.log('NaN');
                return {
                    ...state,
                    mode: action.mode,
                }
            } else {
                console.log('Not NaN');
                return {
                    ...state,
                    mode: action.mode,
                    selected_content_id: action.id
                }
            }

        case SELECTED_CONTENT_CHANGE:
            return {
                ...state,
                selected_content_id: action.id
            }
        case SET_SUBJECT:
            return {
                ...state,
                subject:action.subject
            }
        case CREATE_CONTENT:
            return {
                ...state,
                contents: state.contents.concat(action.newContent),
                mode: action.mode,
                selected_content_id: action.id,
                max_content_id: state.max_content_id++
            }
        case UPDATE_CONTENT:
            return {
                ...state,
                contents: action.contents,
                mode: action.mode
            }
        default:
            return state;

    }
}

export default reducer;