import player from "../../pages/Player";

const initialState = {
    matchRegister : {date: null, stadiumCode: null, link: null, num: 5},
    currentMatch : {match_id: null, date: null, stadiumIndex: null, link: null, num: null, status: null, player: [], playerId: [], playerImages: [], createdBy: null},
    matches : [],
    lastIndex: null,
    activeQuarterResult: null,
    currentMatchLoading: false,
    matchAccordionLoading: false,
    loading: null,
}

export const REGISTER_MATCH_DATE = "REGISTER_MATCH_DATE";
export const REGISTER_MATCH_STADIUM = "REGISTER_MATCH_STADIUM";
export const REGISTER_MATCH_LINK = "REGISTER_MATCH_LINK";
export const REGISTER_MATCH_NUM = "REGISTER_MATCH_NUM";
export const SET_CURRENT_MATCH = "SET_CURRENT_MATCH";
export const CANCEL_CURRENT_MATCH = "CANCEL_CURRENT_MATCH";
export const JOIN_CURRENT_MATCH = "JOIN_CURRENT_MATCH";
export const CANCEL_JOIN_CURRENT_MATCH = "CANCEL_JOIN_CURRENT_MATCH";
export const PAGING_MATCHES = "PAGING_MATCHES";
export const CHANGE_ACTIVE_QUARTER_RESULT = "CHANGE_ACTIVE_QUARTER_RESULT";
export const CURRENT_MATCH_LOADING = "CURRENT_MATCH_LOADING";
export const MATCH_ACCORDION_LOADING = "MATCH_ACCORDION_LOADING";
export const GLOBAL_LOADING = "GLOBAL_LOADING";

function matchReducer(state = initialState, action) {
    switch (action.type) {
        case REGISTER_MATCH_DATE:
            return {
                ...state,
                matchRegister: {
                    date: action.date,
                    stadiumCode: state.matchRegister.stadiumCode,
                    link: state.matchRegister.link,
                    num : state.matchRegister.num
                }
            }
        case REGISTER_MATCH_STADIUM:
            return {
                ...state,
                matchRegister: {
                    date: state.matchRegister.date,
                    stadiumCode: action.stadiumCode,
                    link: state.matchRegister.link,
                    num : state.matchRegister.num
                }
            }
        case REGISTER_MATCH_LINK:
            return {
                ...state,
                matchRegister: {
                    date: state.matchRegister.date,
                    stadiumCode: state.matchRegister.stadiumCode,
                    link: action.link,
                    num : state.matchRegister.num
                }
            }
        case REGISTER_MATCH_NUM:
            return {
                ...state,
                matchRegister: {
                    date: state.matchRegister.date,
                    stadiumCode: state.matchRegister.stadiumCode,
                    link: state.matchRegister.link,
                    num : action.num
                }
            }
        case SET_CURRENT_MATCH:
            return {
                ...state,
                // currentMatch: {
                //     match_id: action.currentMatch.match_id,
                //     date: action.currentMatch.date,
                //     stadiumIndex: action.currentMatch.stadiumIndex,
                //     link: action.currentMatch.link,
                //     num: action.currentMatch.num,
                //     status: action.currentMatch.status,
                //     player: [...state.currentMatch.player, action.currentMatch.player],
                //     playerId: [...state.currentMatch.playerId, action.currentMatch.playerId],
                //     playerImages: [...state.currentMatch.playerImages, action.currentMatch.profileImg]
                // }
                currentMatch: action.currentMatch
            }
        case CANCEL_CURRENT_MATCH:
            return {
                ...state,
                currentMatch: {match_id: null, date: null, stadiumIndex: null, link: null, num: null, status: null, player: [], playerId: [], playerImages: [], createdBy: null}
            }
        case JOIN_CURRENT_MATCH:
            return {
                ...state,
                currentMatch: {
                    match_id: state.currentMatch.match_id,
                    date: state.currentMatch.date,
                    stadiumIndex: state.currentMatch.stadiumIndex,
                    link: state.currentMatch.link,
                    num: state.currentMatch.num,
                    status: state.currentMatch.status,
                    player: [...state.currentMatch.player, action.player],
                    playerId: [...state.currentMatch.playerId, action.playerId],
                    playerImages: [...state.currentMatch.playerImages, action.profileImg]
                }
            }
        case CANCEL_JOIN_CURRENT_MATCH:
            return {
                ...state,
                currentMatch: {
                    match_id: state.currentMatch.match_id,
                    date: state.currentMatch.date,
                    stadiumIndex: state.currentMatch.stadiumIndex,
                    link: state.currentMatch.link,
                    num: state.currentMatch.num,
                    status: state.currentMatch.status,
                    player: [action.player],
                    playerId: [action.playerId]
                }
            }
        case PAGING_MATCHES:
            return {
                ...state,
                matches: state.matches.concat(action.matches),
                lastIndex: action.lastIndex
            }
        case CHANGE_ACTIVE_QUARTER_RESULT:
            return {
                ...state,
                activeQuarterResult: action.activeQuarterResult
            }
        case GLOBAL_LOADING:
            return {
                ...state,
                loading: action.loading
            }
        case CURRENT_MATCH_LOADING:
            console.log(action.loading);
            return {
                ...state,
                currentMatchLoading: action.loading
            }
        case MATCH_ACCORDION_LOADING:
            console.log(action.loading);
            return {
                ...state,
                matchAccordionLoading: action.loading
            }
        default:
            return state;
    }
}

export default matchReducer;