import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {useEffect, useState} from "react";
import axios from "axios";
import {POSITIONS} from "../dropdown/DropdownData";
import {updateMyPage} from "../../store/actions/userAction";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentMatch} from "../../store/actions/matchAction";

function QuarterResultWrite(props) {
    const quarterNum = props.num;
    const matchId = props.matchId;
    const [ updated, setUpdated ] = useState(props.updated);
    const [ disabled, setDisabled ] = useState(false);
    const [ gf, setGf ] = useState(((updated === false) ? null : props.gf));
    const [ ga, setGa ] = useState(((updated === false) ? null : props.ga));
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.loginUser);

    const click = async (e) => {
        e.preventDefault();
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        await axios.put("/api/match/writeQuarterResult/"+matchId+"/"+quarterNum+"/"+gf+"/"+ga, config)
            .then((res) => {
                console.log(res);
                setGf(res.data.gf);
                setGa(res.data.ga);
                setUpdated(res.data.updated);
            }).catch((err) => {
                console.log(err);
            })
    }

    return(
        <Card style={{ width: "auto", alignItems: "center", display: "flex", justifyContent: "center"}}>
            <Card.Body>
                <Card.Title>{quarterNum} 쿼터</Card.Title>
                <InputGroup className="mb-3">
                    <Row>
                        <Col>
                            <InputGroup.Text>스코어</InputGroup.Text>
                        </Col>
                        <Col>
                            <Form.Control width="auto" aria-label="goal" inputMode="numeric" placeholder="득점"
                                  disabled={updated}
                                  onInput={(e) => {
                                      setGf(e.target.value);
                                  }} defaultValue={gf}/>
                        </Col>
                        <Col>
                            <Form.Control width="auto" aria-label="assist" inputMode="numeric" placeholder="실점"
                                disabled={updated}
                              onInput={(e) => {
                                  setGa(e.target.value);
                              }} defaultValue={ga}/>
                        </Col>
                        <Col>
                            <Button variant="primary" disabled={updated} onClick={click}>등록</Button>
                        </Col>
                    </Row>
                </InputGroup>
            </Card.Body>
        </Card>
    )
}

export default QuarterResultWrite;