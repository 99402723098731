import Modal from 'react-bootstrap/Modal';
import {Button} from "react-bootstrap";
import TimeDatePicker from "../time/TimeDatePicker";
import MuiDateTimePicker from "../time/MuiDateTimePicker";
import CustomDropdown from "../dropdown/CustomDropdown";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CustomSelectForm from "../select/CustomSelectForm";
import MatchUrlForm from "./MatchUrlForm";
import CustomRadio from "../radio/CustomRadio";
import {stadiumDropdown} from "../layout/ModalLayout";

function MatchRegisterModalBody() {
    return (
        <div>
            <Modal.Header closeButton closeVariant={"white"} style={{backgroundColor:"#343a40"}}>
                <Modal.Title id="contained-modal-title-vcenter" style={{color: "white"}}>
                    경기 등록하기
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{justifyContent: "center", display: "flex", alignItems: "center", backgroundColor:"lightgray"}}>
                    <div>
                        <Row>
                            <Col>
                                <TimeDatePicker />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <CustomDropdown title="경기장" type={stadiumDropdown} />
                                {/*<CustomSelectForm />*/}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <MatchUrlForm/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <CustomRadio />
                            </Col>
                        </Row>
                    </div>
            </Modal.Body>
        </div>
    )
}

export default MatchRegisterModalBody;