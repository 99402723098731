import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from "./App";
import reportWebVitals from './reportWebVitals';
import {Provider, useDispatch} from "react-redux";
import {persistor, store} from "./store/store";
import {PersistGate} from "redux-persist/integration/react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axios from "axios";
import {setLogin} from "./store/actions/userAction";
import {globalLoading} from "./store/actions/matchAction";


const { dispatch } = store;
axios.defaults.withCredentials = true;
axios.interceptors.response.use (

function (response) {
        dispatch(globalLoading(true));
        // console.log("axios interceptors.response.use - response : ", response);
        return response;
    },
    async function (error){
        console.log(error);
        // console.log("axios interceptors.response.use - error : ", error);
        const jwtCheck = error.response.headers.get("JWT-CHECK")
        // console.log("jwtCheck : ", jwtCheck);
        // const errorCode = error.response.data.code;
        const originalRequest = error.config;
        let isLogout = false;
        if(jwtCheck === "ACCESS_TOKEN_EXPIRED") {
            // console.log('errorStatus is ACCESS_TOKEN_EXPIRED');
            //accessToken 만료
            await axios.post('/api/auth/reissue')
                .then((result) => {
                    // alert("accessToken 재발급 받음");
                    let accessToken = result.data.accessToken;
                    axios.defaults.headers.common['Authorization'] = `${accessToken}`;
                    originalRequest.headers['Authorization'] = `${accessToken}`; //기존 요청의 헤더의 accessToken도 교체해주어야 한다.
                    // console.log('interceptor - accessToken', accessToken);
                    // console.log('originRequest', error.config);
                })
                .catch(error =>{
                    if(error.response.headers.get("JWT-CHECK") === "REFRESH_TOKEN_EXPIRED") {
                        // console.log(error);
                        axios.defaults.headers.common['Authorization'] = ``;
                        isLogout = true;
                        dispatch(setLogin(false));
                        //로그인 페이지로 이동
                        window.location.href = "/join";
                    }
                });

            if(!isLogout) {
                // alert("!isLogout");
                return await axios.request(originalRequest);
            } else {
                alert("로그인이 필요합니다!");
            }

        }
        return Promise.reject(error);
    }
);

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  // <React.StrictMode>
    //react app 전체에 제공할 store를 주입한다.
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>
  // </React.StrictMode>
);
// <App_old /> : import App_old from './App_old'; 를 통해 임포트된 컴포넌트. ./App_old.js 파일을 App이라는 이름으로 import 하였으므로 <App_old />이라고 태그를 붙여야
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
