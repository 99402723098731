import axios from "axios";
import {
    cancelCurrentMatch,
    currentMatchLoad,
    matchAccordionLoad,
    pagingMatches,
    setCurrentMatch
} from "../store/actions/matchAction";
import {useDispatch, useSelector} from "react-redux";

const config = {
    headers: {
        'Content-Type': 'application/json',
    },
};

export default function useAxios() {
    const { currentMatch, currentMatchLoading, matchAccordionLoading, matches} = useSelector(state => state.matchReducer);
    const dispatch = useDispatch();

    const getCurrent = async () => await axios.get("/api/match/currentMatch", config)
        .then((res) => {
            if (res.data.empty === true) {
                if (currentMatch.match_id !== null) {
                    dispatch(cancelCurrentMatch());
                }
                // alert("getCur!!!");
                dispatch(currentMatchLoad(true));
                return;
            }
            const currentMatchData = {
                match_id: res.data.match_id,
                date: res.data.date,
                stadiumIndex: res.data.stadiumIndex,
                link: res.data.link,
                num: res.data.num,
                status: res.data.status,
                player: res.data.players,
                playerId: res.data.playersId,
                playerImages: res.data.playerImages,
                createdBy: res.data.createdBy
            }
            console.log(currentMatchData);
            // alert("getCur!!!");
            dispatch(setCurrentMatch(currentMatchData));
            dispatch(currentMatchLoad(true));
        }).catch((err) => {
            console.log(err);
        })

    const getMatchList = async () => await axios.get("/api/match/read/first", config)
            .then((res) => {
                // alert("getMatchList");
                // console.log(res);
                if(res.data.empty === true) {
                    // alert("매치 기록이 없어요 ㅠㅠ");
                } else {
                    dispatch(pagingMatches(res.data.matchPagingData, res.data.lastIndex));
                    //console.log(res.data.matchPagingData);
                    // console.log(matches);
                }
                dispatch(matchAccordionLoad(true));
            }).catch((err) => {
                console.log(err);
            })


    return {getCurrent, getMatchList};
}