import Reducer from "./index";
import {applyMiddleware, createStore} from "redux";
import promiseMiddleware from "redux-promise";
import ReduxThunk from "redux-thunk";
import { persistStore } from "redux-persist";

const createStoreWithMiddleware = applyMiddleware(promiseMiddleware, ReduxThunk)(createStore)
export const store = createStoreWithMiddleware(Reducer,
                        window.__REDUX_DEVTOOLS_EXTENSION__&&
                                     window.__REDUX_DEVTOOLS_EXTENSION__());

export const persistor = persistStore(store);

export default { store, persistor };
