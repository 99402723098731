import { combineReducers } from "redux";
import userReducer from "./reducers/userReducer";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducer from "./reducers/reducer";
import axios from "axios";
import matchReducer from "./reducers/matchReducer";
import storageSession from "redux-persist/lib/storage/session";
import loginUser from "./reducers/loginUserReducer";
//whitelist에 등록된 리듀서의 state는 새로고침해도 남아있다.
const persistConfig = {
    key: 'root',
    // storage: storage,
    storage: storageSession,
    whitelist: ["loginUser"],
}

// 여러 리듀서를 사용하는 경우 reducer를 하나로 묶어주는 메소드.
// store에 저장되는 리듀서는 오직 1개이기 때문에 여러 리듀서를 하나로 묶어줘야 한다.
const rootReducer = combineReducers({
    reducer, userReducer, matchReducer, loginUser
})

// export default rootReducer;

export default persistReducer(persistConfig, rootReducer);

