import React, {useEffect} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {setUserInfo} from "../store/actions/userAction";
import {silentLogin} from "../index";


const LoginSuccess = (props) => {
    const KakaoLogoutURL = `https://kauth.kakao.com/oauth/logout?client_id=${process.env["REACT_APP_KAKAO-CLIENT-ID"]}&logout_redirect_uri=${process.env["REACT_APP_LOGOUT-REDIRECT-URI"]}`;
    const navigate = useNavigate();
    const { userInfo } = useSelector(state => state.userReducer);
    const nickname = userInfo.kakao_nickname;
    const profile_img = userInfo.profile_img;
    const ping = async () => {
        await axios({
            method: "GET",
            url: "/api/ping",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            params: {
                code: "ping",
            },
        }).then((res) => {
            console.log('resposen = ', res);
            // if(res.data.message === process.env.REACT_APP_JWT_REFRESH_TOKEN_EXPIRED_MSG && res.data.status === 401){
            //     console.log("expired!!");
            //     window.alert("로그인 유효 시간이 만료되었습니다. 다시 로그인해주세요.");
            //     navigate("/join");
            //     return;
            // }
            window.alert("pong!");
        }).catch((err) => {

        });
    }

    return (
        <div>
            로그인 성공!!
            <p></p>
            {nickname}
            <p></p>
            <img src={profile_img}/>
            <p></p>
            <button onClick={ping}>ping!</button>
            <p></p>
            <a href={KakaoLogoutURL}>로그아웃!!</a>
        </div>
    )
}
export default LoginSuccess;