import {
   IS_NEWMEMBER,
   SET_LOGIN,
   SET_PROFILE,
   UPDATE_MYPAGE,
   IS_NEWPROFILEIMG,
   RESET_USERSTATE,
   READ_PLAYERS,
   READ_TOP_GOALS,
   READ_TOP_ASSISTS,
   READ_TOP_RATE,
   READ_TOP_MANAGER_RATE, READ_TOP_MATCH, SET_DEVICETOKEN
} from "../reducers/userReducer";



export const setIsNewMember = (isNewMember) => ({
   type: IS_NEWMEMBER,
   isNewMember: isNewMember
})

export const setLogin = (login) => ({
   type: SET_LOGIN,
   login: login
})

export const setProfile = (profile) => ({
   type: SET_PROFILE,
   profile: profile
})
export const setDeviceToken = (deviceToken) => ({
   type: SET_DEVICETOKEN,
   deviceToken: deviceToken
})
export const updateMyPage = (myPage) => ({
   type: UPDATE_MYPAGE,
   myPage: myPage
})

export const setIsNewProfileImg = (isNewProfileImg) => ({
   type: IS_NEWPROFILEIMG,
   newProfileImg: isNewProfileImg
})

export const resetUserState = () => ({
   type: RESET_USERSTATE,
})

export const readPlayers = (players) => ({
   type: READ_PLAYERS,
   players: players,
})

export const readTopGoals = (topGoals) => ({
   type: READ_TOP_GOALS,
   topGoals: topGoals
})

export const readTopAssists = (topAssists) => ({
   type: READ_TOP_ASSISTS,
   topAssists: topAssists
})

export const readTopRate = (topRate) => ({
   type: READ_TOP_RATE,
   topRate: topRate
})

export const readTopManagerRate = (topManagerRate) => ({
   type: READ_TOP_MANAGER_RATE,
   topManagerRate: topManagerRate
})

export const readTopMatch = (topMatch) => ({
   type: READ_TOP_MATCH,
   topMatch: topMatch
})