import React, {useEffect, useState} from 'react';
import { MDBCard, MDBCardText, MDBCardBody, MDBBtn, MDBTypography } from 'mdb-react-ui-kit';
import MatchAccordion from "../components/match/MatchAccordion";
import PlayerStatsTable from "../components/player/PlayerStatsTable";
import ModalLayout, {playerInfoEditModal} from "../components/layout/ModalLayout";
import PlayerInfoEditModalBody from "../components/player/PlayerInfoEditModalBody";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {POSITIONS} from "../components/dropdown/DropdownData";
import {setProfile, updateMyPage} from "../store/actions/userAction";
import {useNavigate, useParams} from "react-router-dom";
import jerseyImg from "../assets/img/jersey-back.png";
import "../components/css/NewPlayer.css";

function Player() {
    const [modalShow, setModalShow] = useState(false);
    const dispatch = useDispatch();
    const { myPage } = useSelector(state => state.userReducer);
    const { userInfo } = useSelector(state => state.loginUser);
    const { memberId } = useParams();
    const navigate = useNavigate();
    const [ stats, setStats ] = useState();
    const [ playerStatsTable , setPlayerStatsTable ] = useState();
    const [ playerStatsCard, setPlayerStatsCard ] = useState();
    const [ playerPosition, setPlayerPosition ] = useState();
    const [ playerStatsTotal, setPlayerStatsTotal] = useState();
    const [ playerStatsPosition, setPlayerStatsPosition ] = useState();
    useEffect( () => {
        async function fetchData() {

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                params: {
                    member_id: memberId
                }
            };
            await axios.get("/api/member/profile/read", config)
                .then((res) => {
                    let myPage =
                        {
                            name: res.data.name,
                            imgUrl: res.data.imgUrl,
                            jersey_number: res.data.number,
                            position: POSITIONS.at(Number(res.data.positionIndex))
                        };
                    let profile =
                        {
                            name: res.data.name,
                            imgUrl: res.data.imgUrl,
                            jersey_number: res.data.number,
                            position: Number(res.data.positionIndex)
                        };
                    setStats(res.data);
                    renderPlayerStatsTotal(res.data);
                    renderPlayerStatsTable(res.data);
                    renderPlayerStatsPosition(res.data);
                    renderPlayerStatsCard(res.data);
                    dispatch(setProfile(profile));
                    dispatch(updateMyPage(myPage));
                }).catch((err) => {
                    console.log(err);
                })
        }
        fetchData();
    }, []);

    const click = () => {
        setModalShow(true);
    }
    const profileEditBtn = () => {
        return Number(memberId) === userInfo.member_id
            ? <MDBBtn rounded size="sm" onClick={click}>
            프로필 수정
            </MDBBtn>
            : null;
    }
    const renderPlayerStatsTable = (stats) => {
        setPlayerStatsTable(<PlayerStatsTable stats={stats}/>);
    }

    const renderPlayerStatsTotal = (stats) => {
        setPlayerStatsTotal(
            <table className="statsTotalTable">
                <colgroup>
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                </colgroup>
                <thead>
                    <tr>
                        <th className="match" scope="col">매치</th>
                        <th className="goal" scope="col">골</th>
                        <th className="assist" scope="col">어시스트</th>
                        <th className="rate" scope="col">팀원 평점</th>
                        <th className="managerRate" scope="col">감독 평점</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="match" scope="col">{stats.matchCount}</td>
                        <td className="goal" scope="col">{stats.totalGoal}</td>
                        <td className="assist" scope="col">{stats.totalAssist}</td>
                        <td className="rate" scope="col">{Number(stats.rate).toFixed(1)}</td>
                        <td className="managerRate" scope="col">{Number(stats.managerRate).toFixed(1)}</td>
                    </tr>
                </tbody>
            </table>
        )
    }

    const renderPlayerStatsPosition = (stats) => {
        setPlayerStatsPosition(
            <table className="statsPositionTable">
                <colgroup>
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                </colgroup>
                <thead>
                <tr>
                    <th className="match" scope="col">포지션</th>
                    <th className="goal" scope="col">쿼터</th>
                    <th className="assist" scope="col">골</th>
                    <th className="assist" scope="col">어시스트</th>
                    <th className="rate" scope="col">승</th>
                    <th className="managerRate" scope="col">무</th>
                    <th className="managerRate" scope="col">패</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>PIVOT</td>
                    <td>{Number(stats.pivot_Win)+Number(stats.pivot_Draw)+Number(stats.pivot_Lose)}</td>
                    <td>{stats.pivot_Goal}</td>
                    <td>{stats.pivot_Assist}</td>
                    <td>{Number(stats.pivot_Win)}</td>
                    <td>{Number(stats.pivot_Draw)}</td>
                    <td>{Number(stats.pivot_Lose)}</td>
                </tr>
                <tr>
                    <td>LEFT ALA</td>
                    <td>{Number(stats.left_ala_Win)+Number(stats.left_ala_Draw)+Number(stats.left_ala_Lose)}</td>
                    <td>{stats.left_ala_Goal}</td>
                    <td>{stats.left_ala_Assist}</td>
                    <td>{Number(stats.left_ala_Win)}</td>
                    <td>{Number(stats.left_ala_Draw)}</td>
                    <td>{Number(stats.left_ala_Lose)}</td>
                </tr>
                <tr>
                    <td>RIGHT ALA</td>
                    <td>{Number(stats.right_ala_Win)+Number(stats.right_ala_Draw)+Number(stats.right_ala_Lose)}</td>
                    <td>{stats.right_ala_Goal}</td>
                    <td>{stats.right_ala_Assist}</td>
                    <td>{Number(stats.right_ala_Win)}</td>
                    <td>{Number(stats.right_ala_Draw)}</td>
                    <td>{Number(stats.right_ala_Lose)}</td>
                </tr>
                <tr>
                    <td>FIXO</td>
                    <td>{Number(stats.fixo_Win)+Number(stats.fixo_Draw)+Number(stats.fixo_Lose)}</td>
                    <td>{stats.fixo_Goal}</td>
                    <td>{stats.fixo_Assist}</td>
                    <td>{Number(stats.fixo_Win)}</td>
                    <td>{Number(stats.fixo_Draw)}</td>
                    <td>{Number(stats.fixo_Lose)}</td>
                </tr>
                </tbody>
                <thead>
                <tr>
                    <th className="match" scope="col">포지션</th>
                    <th className="goal" scope="col">쿼터</th>
                    <th className="clean" scope="col">클린시트</th>
                    <th className="" scope=""></th>
                    <th className="rate" scope="col">승</th>
                    <th className="managerRate" scope="col">무</th>
                    <th className="managerRate" scope="col">패</th>
                </tr>
                </thead>
                <tbody>
                    <td>GOLEIRO</td>
                    <td>{Number(stats.goleiro_Win)+Number(stats.goleiro_Draw)+Number(stats.goleiro_Lose)}</td>
                    <td>{stats.goleiro_cs}</td>
                    <td></td>
                    <td>{stats.goleiro_Win}</td>
                    <td>{stats.goleiro_Draw}</td>
                    <td>{stats.goleiro_Lose}</td>
                </tbody>
            </table>
        )
    }

    const renderPlayerStatsCard = (stats) => {
        setPlayerStatsCard(
            // <MDBCardText className="text-muted mb-4">
            //     {stats.totalWin} 승 <span className="mx-2">|</span> {stats.totalDraw} 무 <span className="mx-2">|</span> {stats.totalLose} 패 <span className="mx-2">|</span>{stats.mvpCount} MVP
            // </MDBCardText>
            <div className="stats-container">
                <div className="stats">
                    {stats.totalWin} 승 {stats.totalDraw} 무 {stats.totalLose} 패
                </div>
                <div className="mvp">
                    {stats.mvpCount} MVP
                </div>
            </div>
        );
    }
    const renderPlayerPosition = (myPage) => {
        setPlayerPosition(
            <span className={"position-badge-"+myPage.position.position}>{myPage.position.position}</span>
        );
    }
    return (
        <div>
            <div className="summary">
                <div className="profile">
                    <div className="face">
                        <div className="profile-image">
                            <img
                                src={myPage.imgUrl ? myPage.imgUrl :`/icon-profile-cr7.png`}
                                alt="프로필 이미지"
                                style={{width:"100%", height: "100%", objectFit: "cover"}}
                            />
                        </div>
                        <div className="edit-button">
                            {profileEditBtn()}
                        </div>
                    </div>
                    <div className="info">
                        <div className="position-number-container">
                            <div className="name">
                                {"No." +myPage.jersey_number+" "+myPage.name}
                            </div>
                            <div className="position">
                                {myPage.position ? <span className={"profile-position-badge-"+myPage.position.position}>{myPage.position.position}</span> : null}
                                {/*<span className={"position-badge-"+myPage.position.position}>{myPage.position.position}</span>*/}
                                {/*{playerPosition}*/}
                            </div>
                            {/*<div className="number">*/}
                            {/*    No.{myPage.jersey_number}*/}
                            {/*</div>*/}
                        </div>
                        {playerStatsCard}
                    </div>
                </div>
            </div>
            <div className="middle">
                <div className="jersey">
                    <div className="jersey-image">
                        <img src={jerseyImg} />
                        <h2 className="jersey_name">{myPage.name}</h2>
                        <h1 className={`jersey_number_${(Number(myPage.jersey_number).length === 1) ? "one" : "two"}`}>{myPage.jersey_number}</h1>
                    </div>
                </div>
            </div>
            <div className="stats-content">

                <div className="total">
                    <div className="table-title">전체 스탯</div>
                    {playerStatsTotal}
                </div>
                <div className="position">
                    <div className="table-title">포지션별 기록</div>
                    {playerStatsPosition}
                    {/*{playerStatsTable}*/}
                </div>
            </div>
            <ModalLayout show={modalShow} onHide={() => setModalShow(false)} type={playerInfoEditModal} children={<PlayerInfoEditModalBody/>}/>
        </div>

    );
}

export default Player;