import { useNavigate } from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {setIsNewMember, setLogin } from "../store/actions/userAction";
import backgroundImg from "../assets/img/jwgio_team_pic3.jpeg";
import "./LoginHandler.css";
import {setUserInfo} from "../store/actions/loginUserAction";
import {firebaseApp} from "../firebase/firebase";
import useAxios from "../api/api";
const LoginHandler = (props) => {
    const navigate = useNavigate();
    const code = new URL(window.location.href).searchParams.get("code");
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.loginUser);
    useEffect(() => {

        const kakaoLogin = async () => {
            await axios({
                method: "GET",
                url: "/api/kakaoToken",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
                params: {
                    code: code,
                },
            }).then((res) => {
                let isNewMember = res.data.newMember;
                if(isNewMember === true) {
                    alert("현재 신규 회원 가입 기간이 아닙니다.\n비회원 로그인을 이용해주세요.");
                    return;
                }
                let user_info = {member_id: res.data.member_id, username: res.data.username, auth: res.data.auth};
                let accessToken = res.data.accessToken;
                axios.defaults.headers.common['Authorization'] = `${accessToken}`;
                dispatch(setUserInfo(user_info));
                dispatch(setIsNewMember(isNewMember));
                dispatch(setLogin(true));

                const firebaseMessaging = firebaseApp.messaging();
                firebaseMessaging
                    .requestPermission()
                    .then(() => {
                        // 등록 토큰 받기
                        return firebaseMessaging.getToken();
                    })
                    .then(function (token) {
                        if(token !== undefined && res.data.member_id != null) {
                            const sendDeviceToken = async () => {
                                await axios({
                                    method: "GET",
                                    url: "/api/auth/deviceToken",
                                    headers: {
                                        "Content-Type": "application/json;charset=utf-8",
                                    },
                                    params: {
                                        deviceToken: token,
                                        member_id: res.data.member_id
                                    },
                                }).then((res) => {
                                    console.log(res);
                                }).catch((err) => console.log(err));
                            };
                            sendDeviceToken();
                        }
                    })
                    .catch(function (error) {
                        console.log("FCM Error : ", error);
                    });
            });
        };
        kakaoLogin();
        navigate("/main");
    }, [props.history]);

    return (
        <div className="login-wrapper">
            <div className="background-div">
                <img
                    className="background-img"
                    src={backgroundImg}
                />
                <div className="title">
                    <h1 className="title-text">FC 정우지오</h1>
                </div>
            </div>
        </div>
    )

};

export default LoginHandler;