import React, {useEffect} from "react";
import KakaoLoginButton from "../components/kakao/KakaoLoginButton";
import "./Join.css";
import playerImg from "../assets/img/jwgio_all_pic.jpeg";
import NoLoginButton from "../components/kakao/NoLoginButton";
import useAxios from "../api/api";
function Join() {

    return (
        <div className="join-wrapper">
            <div className="playerImg-div">
                <img
                    className="playerImg"
                    src={playerImg}
                />
                <div className="title">
                    <h1 className="title-text">FC 정우지오</h1>
                </div>
                {/*<KakaoLoginButton className="kakao-login-btn"/>*/}
                <NoLoginButton className="no-login-btn" />
            </div>
            {/*<a>{process.env["REACT_APP_SERVICE-TYPE"]}</a>*/}

        </div>
    )
}

export default Join;