import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ko from "date-fns/locale/ko";
import "./TimeDatePicker.css";
import {useEffect, useState} from "react";
import CustomInput from "../dropdown/CustomInput";
import {useDispatch, useSelector} from "react-redux";
import {registerMatchDate} from "../../store/actions/matchAction";

function TimeDatePicker () {
    const dispatch = useDispatch();
    const nowDate = new Date();
    const [startDate, setStartDate] = useState(nowDate);
    const { matchRegister } = useSelector(state => state.matchReducer);
    useEffect(() => {
        dispatch(registerMatchDate(startDate));
    }, []);
    const getDayName = (date) => {
        return date.toLocaleDateString('ko-KR', {
            weekday: 'long',
        }).substring(0, 1);
    }

    const createDate = (date) => {
        return new Date(new Date(date.getFullYear()
        , date.getMonth()
        , date.getDate()
        , 0
        , 0
        , 0));
    }
    const change = (date) => {
        setStartDate(date);
        dispatch(registerMatchDate(date));
    }
    return (
        <div id="div-wrap-DatePicker">
            <DatePicker
                selected={startDate} //value
                onChange={(date) => change(date)}
                onFocus={e => e.target.blur()} //모바일에서 날짜 선택 시 키보드 안뜨도록
                showTimeSelect
                timeCaption="시간"
                disabledKeyboardNavigation
                showIcon
                locale={ko}
                dateFormat="yyyy/MM/dd HH:mm"
                timeIntervals="30"
                placeholderText="경기 날짜를 선택하세요"
                minDate={nowDate}
                fixedHeight
                customInput={<CustomInput/>}
                popperModifiers={{ // 모바일 web 환경에서 화면을 벗어나지 않도록 하는 설정
                    preventOverflow: {
                        enabled: true,
                    },
                }}
                showPopperArrow={false}
                dayClassName={date =>
                    getDayName(createDate(date)) === '토' ? "saturday"
                        :
                        getDayName(createDate(date)) === '일' ? "sunday" : undefined
                }
            />
        </div>
    );
}

export default TimeDatePicker;