import Card from 'react-bootstrap/Card';
import {Button, Badge} from "react-bootstrap";
import ModalLayout, {matchRegisterModal} from "../layout/ModalLayout";
import React, {useEffect, useState} from "react";
import MatchRegisterModalBody from "../match/MatchRegisterModalBody";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import PlayerInfoEditModalBody from "../player/PlayerInfoEditModalBody";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {POSITIONS, STADIUMS} from "../dropdown/DropdownData";
import {updateMyPage} from "../../store/actions/userAction";
import {useNavigate} from "react-router-dom";
import NumberToDayOfWeekConverter from "../time/NumberToDayOfWeekConverter";
import {
    cancelCurrentMatch,
    cancelJoinCurrentMatch, currentMatchLoading,
    joinCurrentMatch,
    setCurrentMatch
} from "../../store/actions/matchAction";
import "../css/CurrentMatch.css";

function CurrentMatch() {
    const dispatch = useDispatch();
    const { currentMatch, currentMatchLoading } = useSelector(state => state.matchReducer);
    const { userInfo } = useSelector(state => state.loginUser);
    const [modalShow, setModalShow] = useState(false);
    const navigate = useNavigate();
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    // useEffect( () => {
    //     async function fetchData() {
    //         alert("fetchData in CurrentMatch");
    //         await axios.get("/api/match/currentMatch", config)
    //             .then((res) => {
    //                 if(res.data.empty === true) {
    //                     if( currentMatch.match_id !== null) {
    //                         dispatch(cancelCurrentMatch());
    //                     }
    //                     return;
    //                 }
    //                 const currentMatchData = {
    //                     match_id: res.data.match_id,
    //                     date: res.data.date,
    //                     stadiumIndex: res.data.stadiumIndex,
    //                     link: res.data.link,
    //                     num: res.data.num,
    //                     status: res.data.status,
    //                     player: res.data.players,
    //                     playerId: res.data.playersId,
    //                     createdBy: res.data.createdBy
    //                 }
    //                 dispatch(setCurrentMatch(currentMatchData));
    //             }).catch((err) => {
    //                 console.log(err);
    //             })
    //     }
    // }, []);

    const cancelMatch = async () => {

        await axios.get("/api/match/currentMatch", config)
            .then(async (res) => {
                if (res.data.empty === true) {
                    if (currentMatch.match_id !== null) {
                        dispatch(cancelCurrentMatch());
                    }
                    return;
                }
                const currentMatchData = {
                    match_id: res.data.match_id,
                    date: res.data.date,
                    stadiumIndex: res.data.stadiumIndex,
                    link: res.data.link,
                    num: res.data.num,
                    status: res.data.status,
                    player: res.data.players,
                    playerId: res.data.playersId,
                    playerImages: res.data.playerImages,
                    createdBy: res.data.createdBy
                }
                if (res.data.status === 0 || res.data.status === 1) { //상태가 0이나 1일때만 매치 취소가 가능함
                    await axios.delete("/api/match/cancel/" + currentMatch.match_id)
                        .then((res) => {
                            if (res.status === 200) {
                                dispatch(cancelCurrentMatch());
                                alert("매치를 취소하였습니다!");
                            }
                        }).catch((err) => {
                            console.log(err);
                        })
                } else {
                    alert("이미 시작한 매치는 취소할 수 없어요 ㅠㅠ");
                    dispatch(setCurrentMatch(currentMatchData));
                    return;
                }
            }).catch((err) => {
                console.log(err);
            })
    }

    const joinMatch = async () => {
        await axios.put("/api/match/join/" +currentMatch.match_id +"/"+userInfo.member_id)
            .then((res) => {
                if(res.data.full === true) {
                    alert("팀원이 이미 모두 모였어요 ㅠㅠ");
                    return;
                }
                if(res.data.canceled === true) {
                    alert("매치가 취소되었어요 ㅠㅠ");
                    dispatch(cancelCurrentMatch());
                    return;
                }
                alert("참가 완료!");
                dispatch(joinCurrentMatch(res.data.username, res.data.member_id, res.data.profileImg));
            }).catch((err) => {
                console.log(err);
            })
    }

    const cancelJoinMatch = async () => {
        await axios.put("/api/match/cancelJoin/"+currentMatch.match_id +"/"+userInfo.member_id)
            .then(async (res) => {
                if (res.data.exist === false && res.data.playing === false) {
                    alert("이미 매치가 취소되었어요.");
                    console.log(res.data);
                    dispatch(cancelCurrentMatch());
                } else if (res.data.playing === true) {
                    alert("이미 시작한 매치입니다.");
                    await axios.get("/api/match/currentMatch", config)
                        .then((res) => {
                            if (res.data.empty === true) {
                                if (currentMatch.match_id !== null) {
                                    dispatch(cancelCurrentMatch());
                                }
                                return;
                            }
                            const currentMatchData = {
                                match_id: res.data.match_id,
                                date: res.data.date,
                                stadiumIndex: res.data.stadiumIndex,
                                link: res.data.link,
                                num: res.data.num,
                                status: res.data.status,
                                player: res.data.players,
                                playerId: res.data.playersId,
                                playerImages: res.data.playerImages,
                                createdBy: res.data.createdBy
                            }
                            dispatch(setCurrentMatch(currentMatchData));
                        }).catch((err) => {
                            console.log(err);
                        })
                } else {
                    console.log(res.data);
                    dispatch(cancelJoinCurrentMatch(res.data.players, res.data.playersId));
                }
            }).catch((err) => {
                console.log(err);
            })
    }

    const register = async () => {
        if(userInfo.member_id === null) {
            alert("정우지오 회원만 매치를 등록할 수 있어요");
            return;
        }
        await axios.post("/api/match/checkExist")
            .then(async (res) => {
                if (res.data.exist === true) {
                    alert('이미 다른 팀원이 매치를 등록했어요');
                    const config = {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    };
                    await axios.get("/api/match/currentMatch", config)
                        .then((res) => {
                            console.log(res);
                            const currentMatch = {
                                match_id: res.data.match_id,
                                date: res.data.date,
                                stadiumIndex: res.data.stadiumIndex,
                                link: res.data.link,
                                num: res.data.num,
                                status: res.data.status,
                                player: res.data.players,
                                playerId: res.data.playersId,
                                playerImages: res.data.playerImages,
                                createdBy: res.data.createdBy
                            }
                            dispatch(setCurrentMatch(currentMatch));
                        }).catch((err) => {
                            console.log(err);
                        })
                    return;
                } else {
                    setModalShow(true);
                }
            }).catch((err) => {
                console.log(err);
            })
    }
    const completeMatch = async () => {
        await axios.put("/api/match/completeMatch")
            .then(async (res) => {
                if (res.data.complete === false) {
                    alert('아직 결과를 입력하지 않은 팀원이 있어요.');
                    return;
                } else {
                    dispatch(cancelCurrentMatch());
                    matchCompletePush();
                }
            }).catch((err) => {
                console.log(err);
            })
    }
    const matchCompletePush = async () => {
        await axios.get("/api/push/match/complete/")
            .then((res) => {

            }).catch((err) => {
                console.log(err);
            })
    }

    //Buttons
    const joinButton = () => {
        let isJoined = false;
        currentMatch.playerId.map((id) => {
            if(id === userInfo.member_id) {
                isJoined = true;
            }
        })
        if(isJoined === false && userInfo.member_id !== null) {
            // return <Button style={{marginLeft: "2%"}} size={"sm"} onClick={joinMatch}>경기 참가</Button>;
            return (
                <button className="register-button" onClick={joinMatch}>
                    {"경기 참가"}
                </button>
            );
        } else if(isJoined === true && currentMatch.createdBy !== userInfo.member_id) {
            // return <Button style={{marginLeft: "2%"}} size={"sm"} onClick={cancelJoinMatch}>참가 취소</Button>
            return (
                <button className="register-button" onClick={cancelJoinMatch}>
                    {"참가 취소"}
                </button>
            );
        }
    }

    const cancelMatchButton = () => {
        if(currentMatch.createdBy === userInfo.member_id) {
            // return <Button style={{marginLeft: "2%"}} size={"sm"} onClick={cancelMatch}>경기 취소</Button>;
            return (
                <button className="register-button" onClick={cancelMatch}>
                    {"경기 취소"}
                </button>
            )
        }
    }
    const matchResultWriteButton = () => {
        let isJoined = false;
        currentMatch.playerId.map((id) => {
            if(id === userInfo.member_id) {
                isJoined = true;
            }
        })
        if(isJoined === true) {
            // return <Button style={{marginLeft: "2%"}} size={"sm"} onClick={() => navigate('/MatchResultWrite/'+currentMatch.match_id)}>기록 입력</Button>;
            return (
                <button className="register-button" onClick={() => navigate('/MatchResultWrite/'+currentMatch.match_id)}>
                    {"기록 입력"}
                </button>
            )
        }
    }

    const matchCompleteButton = () => {
        if(currentMatch.createdBy === userInfo.member_id) {
            // return <Button style={{marginLeft: "2%"}} size={"sm"} onClick={completeMatch}>기록 완료</Button>;
            return (
                <button className="register-button" onClick={completeMatch}>
                    {"기록 완료"}
                </button>
            )
        }
    }

    const cardBody = () => {
        if(currentMatch.match_id === null) {
            return (
                // <Card.Body>
                //     <Card.Title>경기 정보</Card.Title>
                //     <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                //     <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                //     <Card.Text>
                //         경기를 등록해주세요!
                //     </Card.Text>
                //     <Button style={{marginLeft: "2%"}} size={"sm"} onClick={register}>경기 등록</Button>
                //     <ModalLayout show={modalShow} onHide={() => setModalShow(false)} type={matchRegisterModal} children={<MatchRegisterModalBody />}/>
                // </Card.Body>
                <div className={"currentMatchItemWrap"}>
                    <div className={"deco"}></div>
                    <div className="currentMatch-content">
                        <div className={"currentMatchHeader"}>
                            <div className="head-group">
                                <div className="matchStatus">
                                    {"등록된 경기 없음"}
                                </div>
                            </div>
                            <div className="head-group">
                                <div className="buttons">
                                    <button className="register-button" onClick={register}>
                                        {"경기 등록"}
                                    </button>
                                    <ModalLayout show={modalShow} onHide={() => setModalShow(false)} type={matchRegisterModal} children={<MatchRegisterModalBody />}/>
                                </div>
                            </div>
                        </div>
                        <div className={"currentMatchBody"}>
                            <div className="main">
                                {"경기를 등록해주세요"}
                            </div>
                            <div className="sub">

                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            const arr1 = currentMatch.date.split('T')
            const dateString = arr1[0];
            const day = NumberToDayOfWeekConverter(new Date(dateString).getDay());
            const timeString = arr1[1];
            const arr2 = dateString.split('-');
            const year = arr2[0];
            const month = arr2[1];
            const date = arr2[2];
            const arr3 = timeString.split(':');
            const hour = arr3[0];
            const min = arr3[1];
            const stadiumName = STADIUMS.at(currentMatch.stadiumIndex).name;

            if(currentMatch.status === 0) { //대기중
                return (
                    // <Card.Body>
                    //     <Card.Title>경기 정보</Card.Title>
                    //     <Card.Subtitle className="mb-2 text-muted">{year}년 {month}월 {date}일 {day}요일 {hour}시 {min}분 </Card.Subtitle>
                    //     <Card.Subtitle className="mb-2 text-muted">{stadiumName} {currentMatch.num} vs {currentMatch.num} <Badge bg="success">{currentMatch.num - currentMatch.player.length}명 더!</Badge></Card.Subtitle>
                    //     <Card.Subtitle className="mb-2 text-muted"><a href={currentMatch.link} target="_blank">신청 링크</a></Card.Subtitle>
                    //     <Card.Text>
                    //         참가자 : {currentMatch.player.map(name => name + " ")}
                    //
                    //     </Card.Text>
                    //     {/*<Button style={{marginLeft: "2%"}} size={"sm"} onClick={joinMatch}>경기 참가</Button>*/}
                    //     {joinButton()}
                    //     {cancelMatchButton()}
                    // </Card.Body>
                    <div className={"currentMatchItemWrap"}>
                        <div className={"deco"}></div>
                        <div className="currentMatch-content">
                            <div className={"currentMatchHeader"}>
                                <div className="head-group">
                                    <div className="matchStatus">
                                        {currentMatch.num - currentMatch.player.length}명 더!
                                    </div>
                                </div>
                                <div className="head-group">
                                    <div className="buttons">
                                        {joinButton()}
                                        {cancelMatchButton()}
                                    </div>
                                </div>
                            </div>
                            <div className={"currentMatchBody"}>
                                <div className="main">
                                    <div className="matchDate">
                                        {year}-{month}-{date} {day} {hour}:{min}
                                    </div>
                                    <div className="matchType">
                                        {currentMatch.num + " vs " + currentMatch.num}
                                    </div>
                                    <div className="matchStadium">
                                        {stadiumName}
                                    </div>
                                    {/*<div className="names">*/}
                                    {/*    참가자 : {currentMatch.player.map(name => name + " ")}*/}
                                    {/*</div>*/}
                                </div>
                                <div className="sub">
                                    <div className="players">
                                        {
                                            currentMatch.playerImages.map((img, index) => {
                                                if(img !== null) {
                                                    return(
                                                        <div className="player">
                                                            <img
                                                                className="playerProfileImage"
                                                                src={img}
                                                                onClick={() => navigate('/player/'+currentMatch.playerId.at(index))}
                                                            />
                                                        </div>
                                                    );
                                                } else {
                                                    return(
                                                        <dd className="player"></dd>
                                                    );
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                 )
            } else if (currentMatch.status === 1) {//인원 꽉참.
                return (
                    // <Card.Body>
                    //     <Card.Title>경기 정보</Card.Title>
                    //     <Card.Subtitle className="mb-2 text-muted">{year}년 {month}월 {date}일 {day}요일 {hour}시 {min}분 </Card.Subtitle>
                    //     <Card.Subtitle className="mb-2 text-muted">{stadiumName} {currentMatch.num} vs {currentMatch.num} <Badge bg="success">준비 완료!</Badge></Card.Subtitle>
                    //     <Card.Text>
                    //         참가자 : {currentMatch.player.map(name => name + " ")}
                    //     </Card.Text>
                    //     {joinButton()}
                    //     {cancelMatchButton()}
                    // </Card.Body>
                    <div className={"currentMatchItemWrap"}>
                        <div className={"deco"}></div>
                        <div className="currentMatch-content">
                            <div className={"currentMatchHeader"}>
                                <div className="head-group">
                                    <div className="matchStatus">
                                        {"준비 완료"}
                                    </div>
                                </div>
                                <div className="head-group">
                                    <div className="buttons">
                                        {joinButton()}
                                        {cancelMatchButton()}
                                    </div>
                                </div>
                            </div>
                            <div className={"currentMatchBody"}>
                                <div className="main">
                                    <div className="matchDate">
                                        {year}-{month}-{date} {day} {hour}:{min}
                                    </div>
                                    <div className="matchType">
                                        {currentMatch.num + " vs " + currentMatch.num}
                                    </div>
                                    <div className="matchStadium">
                                        {stadiumName}
                                    </div>
                                    {/*<div className="names">*/}
                                    {/*    참가자 : {currentMatch.player.map(name => name + " ")}*/}
                                    {/*</div>*/}
                                </div>
                                <div className="sub">
                                    <div className="players">
                                        {
                                            currentMatch.playerImages.map((img, index) => {
                                                if(img !== null) {
                                                    return(
                                                        <div className="player">
                                                            <img
                                                                className="playerProfileImage"
                                                                src={img}
                                                                onClick={() => navigate('/player/'+currentMatch.playerId.at(index))}
                                                            />
                                                        </div>
                                                    );
                                                } else {
                                                    return(
                                                        <dd className="player"></dd>
                                                    );
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else if (currentMatch.status === 2) {// status === 2 진행중
                return (
                    // <Card.Body>
                    //     <Card.Title>경기 정보</Card.Title>
                    //     <Card.Subtitle className="mb-2 text-muted">{year}년 {month}월 {date}일 {day}요일 {hour}시 {min}분 </Card.Subtitle>
                    //     <Card.Subtitle className="mb-2 text-muted">{stadiumName} {currentMatch.num} vs {currentMatch.num} <Badge bg="success">경기 중!</Badge></Card.Subtitle>
                    //     <Card.Text>
                    //         참가자 : {currentMatch.player.map(name => name + " ")}
                    //     </Card.Text>
                    //     {matchResultWriteButton()}
                    // </Card.Body>
                    <div className={"currentMatchItemWrap"}>
                        <div className={"deco"}></div>
                        <div className="currentMatch-content">
                            <div className={"currentMatchHeader"}>
                                <div className="head-group">
                                    <div className="matchStatus">
                                        {"경기 중"}
                                    </div>
                                </div>
                                <div className="head-group">
                                    <div className="buttons">
                                        {matchResultWriteButton()}
                                    </div>
                                </div>
                            </div>
                            <div className={"currentMatchBody"}>
                                <div className="main">
                                    <div className="matchDate">
                                        {year}-{month}-{date} {day} {hour}:{min}
                                    </div>
                                    <div className="matchType">
                                        {currentMatch.num + " vs " + currentMatch.num}
                                    </div>
                                    <div className="matchStadium">
                                        {stadiumName}
                                    </div>
                                    {/*<div className="names">*/}
                                    {/*    참가자 : {currentMatch.player.map(name => name + " ")}*/}
                                    {/*</div>*/}
                                </div>
                                <div className="sub">
                                    <div className="players">
                                        {
                                            currentMatch.playerImages.map((img, index) => {
                                                if(img !== null) {
                                                    return(
                                                        <div className="player" key={index}>
                                                            <img

                                                                className="playerProfileImage"
                                                                src={img}
                                                                onClick={() => navigate('/player/'+currentMatch.playerId.at(index))}
                                                            />
                                                        </div>
                                                    );
                                                } else {
                                                    return(
                                                        <dd className="player"></dd>
                                                    );
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else if(currentMatch.status === 3){
                //status === 3 종료
                return (
                    // <Card.Body>
                    //     <Card.Title>경기 정보</Card.Title>
                    //     <Card.Subtitle className="mb-2 text-muted">{year}년 {month}월 {date}일 {day}요일 {hour}시 {min}분 </Card.Subtitle>
                    //     <Card.Subtitle className="mb-2 text-muted">{stadiumName} {currentMatch.num} vs {currentMatch.num} <Badge bg="success">경기 종료</Badge></Card.Subtitle>
                    //     <Card.Text>
                    //         참가자 : {currentMatch.player.map(name => name + " ")}
                    //     </Card.Text>
                    //     {matchResultWriteButton()}
                    //     {matchCompleteButton()}
                    // </Card.Body>
                    <div className={"currentMatchItemWrap"}>
                        <div className={"deco"}></div>
                        <div className="currentMatch-content">
                            <div className={"currentMatchHeader"}>
                                <div className="head-group">
                                    <div className="matchStatus">
                                        {"경기 종료"}
                                    </div>
                                </div>
                                <div className="head-group">
                                    <div className="buttons">
                                        {matchResultWriteButton()}
                                        {matchCompleteButton()}
                                    </div>
                                </div>
                            </div>
                            <div className={"currentMatchBody"}>
                                <div className="main">
                                    <div className="matchDate">
                                        {year}-{month}-{date} {day} {hour}:{min}
                                    </div>
                                    <div className="matchType">
                                        {currentMatch.num + " vs " + currentMatch.num}
                                    </div>
                                    <div className="matchStadium">
                                        {stadiumName}
                                    </div>
                                    {/*<div className="names">*/}
                                    {/*    참가자 : {currentMatch.player.map(name => name + " ")}*/}
                                    {/*</div>*/}
                                </div>
                                <div className="sub">
                                    <div className="players">
                                        {
                                            currentMatch.playerImages.map((img, index) => {
                                                if(img !== null) {
                                                    return(
                                                        <div className="player">
                                                            <img
                                                                className="playerProfileImage"
                                                                src={img}
                                                                onClick={() => navigate('/player/'+currentMatch.playerId.at(index))}
                                                            />
                                                        </div>
                                                    );
                                                } else {
                                                    return(
                                                        <dd className="player"></dd>
                                                    );
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }

    return (
        // <Card style={{ width: "auto"}}>
        //     {/*<Card.Body>*/}
        //     {/*    <Card.Title>{currentMatch === null ? "경기를 등록해주세요" : "경기 정보"} </Card.Title>*/}
        //     {/*    <Card.Subtitle className="mb-2 text-muted">2023년 9월 29일 금요일 22:00</Card.Subtitle>*/}
        //     {/*    <Card.Subtitle className="mb-2 text-muted">야탑 NC백화점 스카이필드 5 vs 5 <Badge bg="success">진행중</Badge></Card.Subtitle>*/}
        //     {/*    <Card.Text>*/}
        //     {/*        참가자 : 홍길동 홍길동 홍길동 홍길동 홍길동*/}
        //     {/*    </Card.Text>*/}
        //     {/*    <Card.Link href="/MatchResultWrite">기록하기</Card.Link>*/}
        //     {/*    <Button style={{marginLeft: "2%"}} size={"sm"} onClick={() => setModalShow(true)}>경기 등록</Button>*/}
        //     {/*    <Button style={{marginLeft: "2%"}} size={"sm"} onClick={() => setModalShow(true)}>경기 취소</Button>*/}
        //     {/*    <ModalLayout show={modalShow} onHide={() => setModalShow(false)} type={matchRegisterModal} children={<MatchRegisterModalBody />}/>*/}
        //     {/*</Card.Body>*/}
        //     {cardBody()}
        // </Card>
        <div>
            {cardBody()}
        </div>
    )
}
export default CurrentMatch;