import ItemsCarousel from "react-items-carousel";
import {useState} from "react";
import MatchCard from "../match/MatchCard";

function MainCarousel() {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    return (
        <div className="MainCarousel">
            <ItemsCarousel
                infiniteLoop={false}
                chevronWidth={0}
                requestToChangeActive={setActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={1}
                gutter={10}
                disableSwipe={false}
                slideToScroll={1}
                leftChevron={<button>{'<'}</button>}
                rightChevron={<button>{'>'}</button>}
            >
                <MatchCard/>
                <MatchCard match={null}/>
                <MatchCard match={null}/>
                <MatchCard match={null}/>
            </ItemsCarousel>
        </div>
    );
}

export default MainCarousel;