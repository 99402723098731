import React from 'react';
import PlayerTable from "../components/player/PlayerTable";
import Card from "react-bootstrap/Card";
import HorizonLine from "../components/HorizonLine";

function Players() {
    return (
        <div>
            <PlayerTable title="선수명단" type="all"/>
        </div>
    );
}

export default Players;