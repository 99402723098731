import Modal from "react-bootstrap/Modal"
import "./PlayerInfoEditModalBody.css";
import {useRef, useState} from "react";
import {Button, Stack} from "react-bootstrap";
import jerseyImg from "../../assets/img/jersey-back.png";
import {useDispatch, useSelector} from "react-redux";
import {setIsNewProfileImg, setProfile} from "../../store/actions/userAction";
import {positionDropdown} from "../layout/ModalLayout";
import CustomDropdown from "../dropdown/CustomDropdown";
function PlayerInfoEditModalBody(props) {
    const imgRef = useRef();
    const newMember = props.newMember;
    const { profile, myPage, newProfileImg } = useSelector(state => state.userReducer);
    const [jerseyName, setJerseyName] = useState(
        myPage.name ? myPage.name : ""
    );
    const [jerseyNumber, setJerseyNumber] = useState(
        myPage.jersey_number ? myPage.jersey_number : null
    );
    const [numberLengthOne, setNumberLengthOne] = useState(true);
    const dispatch = useDispatch();
    const [imgFile, setImgFile] = useState(); // 미리보기

    const click = e => {
        imgRef.current.click();
    }
    const changeJerseyName = (e) => {
        let { value } = { ...e.target }
        setJerseyName(value);
        let temp = {name: value, img: profile.img, jersey_number: profile.jersey_number, position: profile.position }
        dispatch(setProfile(temp));
    }
    const changeJerseyNumber = (e) => {
        let { value } = { ...e.target };
        if(value.length > 1) {
            setNumberLengthOne(false);
        } else {
            setNumberLengthOne(true);
        }

        let jerseyNumber = Number(value);
        if(Number(value) === 0) {
            jerseyNumber = null;
        }
        setJerseyNumber(jerseyNumber);
        let temp = {name: profile.name, img: profile.img, jersey_number: jerseyNumber, position: profile.position }
        dispatch(setProfile(temp))
    }

    // 이미지 업로드 input의 onChange
    const saveImgFile = () => {
        const file = imgRef.current.files[0];
        console.log(file);
        const reader = new FileReader();
        let temp = {name: profile.name, img: file, jersey_number: profile.jersey_number, position: profile.position }
        dispatch(setProfile(temp));
        dispatch(setIsNewProfileImg(true));
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setImgFile(reader.result);
        };
    };
    const modalHeader = () => {
        if(newMember === true) {
            return (
                <Modal.Header closeVariant={"white"} style={{backgroundColor:"#343a40"}}>
                    <Modal.Title id="contained-modal-title-vcenter" style={{color: "white"}}>
                        선수 정보 등록
                    </Modal.Title>
                </Modal.Header>
            )
        } else {
            return (
                <Modal.Header closeButton closeVariant={"white"} style={{backgroundColor:"#343a40"}}>
                    <Modal.Title id="contained-modal-title-vcenter" style={{color: "white"}}>
                        선수 정보 수정
                    </Modal.Title>
                </Modal.Header>
            )
        }
    }
    const imgPreview = () => {
        if(newProfileImg === false) {
            return (
                <img
                    src={myPage.imgUrl ? myPage.imgUrl :`/icon-profile-cr7.png`}
                    alt="프로필 이미지"
                    style={{width:"100%", height: "100%", objectFit: "cover"}}
                />
            )
        } else if(newProfileImg === true) {
            return (
                <img
                    src={imgFile ? imgFile :`/icon-profile-cr7.png`}
                    alt="프로필 이미지"
                    style={{width:"100%", height: "100%", objectFit: "cover"}}
                />
            )
        }
    }
    return (
        <div>
            {modalHeader()}
            <Modal.Body style={{backgroundColor:"lightgray"}}>
                   <Stack style={{justifyContent: "center", display: "flex", alignItems: "center", backgroundColor:"lightgray"}}>
                       <div style={{
                           background: "lightblue",
                           width:"150px",
                           height:"150px",
                           borderRadius: "50%",
                           overflow: "hidden",
                           position: "inherit",
                           borderStyle: "solid",
                           borderColor: "black",
                           marginBottom: "1%"
                       }}>
                           <img
                               src={newProfileImg === false ? (myPage.imgUrl ? myPage.imgUrl : `/icon-profile-cr7.png`)
                                   : imgFile}
                               alt="프로필 이미지"
                               style={{width:"100%", height: "100%", objectFit: "cover"}}
                           />
                       </div>
                       <Button variant="primary" size="sm" onClick={click}>프로필 사진 업로드</Button>
                       <input id="img-input"
                              type="file"
                              ref={imgRef}
                              onChange={saveImgFile}
                              accept="image/*"
                       />
                       <div className="jersey_image">
                           <img src={jerseyImg} />
                           <h2 className="jersey_name">{jerseyName}</h2>
                           <h1 className={`jersey_number_${numberLengthOne ? "one" : "two"}`}>{jerseyNumber}</h1>
                       </div>
                        <input type="text" onChange={changeJerseyName} placeholder="이름을 입력하세요" defaultValue={myPage.name ? myPage.name : ""}
                        />
                        <input type="number"
                               inputMode="numeric"
                               onChange={changeJerseyNumber}
                               min="1" max="99"
                               maxLength={2}
                               onInput={(e) => {
                                   if (e.target.value.length > e.target.maxLength)
                                       e.target.value = e.target.value.slice(0, e.target.maxLength);
                               }}
                               defaultValue={myPage.jersey_number ? myPage.jersey_number : null}
                        />
                       <div><CustomDropdown title="포지션 선택" type={positionDropdown} /></div>
                   </Stack>
            </Modal.Body>
        </div>
    )
}

export default PlayerInfoEditModalBody;