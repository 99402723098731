import React, {useEffect, useState} from "react";
import './App.css';
import "./components/css/MatchAccordian.css";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Join from "./pages/Join"
import LoginSuccess from "./pages/LoginSuccess";
import LoginHandler from "./pages/LoginHandler";
import Main from "./pages/Main";
import MatchResultWrite from "./components/match/MatchResultWrite";
import {BrowserView, MobileView} from "react-device-detect";
import Players from "./pages/Players";
import Records from "./pages/Records";
import Player from "./pages/Player";
import Footer from "./components/layout/Footer";
import Header from "./components/layout/Header";
import ErrorPage from "./pages/ErrorPage";
import {firebaseApp} from "./firebase/firebase";
import A2HS from "./a2hs/A2HS";
import userReducer from "./store/reducers/userReducer";
import axios from "axios";
import {setUserInfo} from "./store/actions/loginUserAction";
import {setIsNewMember, setLogin} from "./store/actions/userAction";
import {useSelector} from "react-redux";
import Loading from "./pages/Loading";
import MatchList from "./components/match/MatchList";
import NewPlayer from "./pages/NewPlayer";

function App() {
    const [deviceToken, setDeviceToken] = useState();
    const { loading } = useSelector(state => state.matchReducer);
    function setScreenSize() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
    useEffect(() => {
        setScreenSize();
    });

    window.onpageshow = function(event) {
        if ( event.persisted || (window.performance && performance.getEntriesByType("navigation")[0].type === 'back_forward') && window.location.pathname === '/main') {
            // Back Forward Cache로 브라우저가 로딩될 경우 혹은 브라우저 뒤로가기 했을 경우
            alert("back btn click");
            window.open('','_self').close();
        }
    }
    // const firebaseMessaging = firebaseApp.messaging();


    // firebaseMessaging.onMessage((payload) => {
    //     console.log("message!!");
    //     console.log(payload.notification.title);
    //     console.log(payload.notification.body);
    //     console.log(payload.notification.image);
    // });

    const a2hs = A2HS();

    return (
        <BrowserRouter>
            <BrowserView>
                <div id="wrapper" style={{margin: "1% 20% 1%"}}>
                    <Header/>
                    <div id="content">
                        {a2hs}
                        <Routes>
                            <Route path="/main" element={<Main/>}></Route>
                            <Route path="/" element={<Join/>}></Route>
                            <Route path="/loginSuccess" element={<LoginSuccess/>}></Route>
                            <Route path="/loginHandler" element={<LoginHandler/>}></Route>
                            <Route path="/matchResultWrite/:matchId" element={<MatchResultWrite/>}></Route>
                            <Route path="/oauth2/code/kakao" element={<LoginHandler/>}></Route>
                            <Route path="/players" element={<Players/>}></Route>
                            <Route path="/records" element={<Records/>}></Route>
                            <Route path="/player/:memberId" element={<NewPlayer/>}></Route>
                            <Route path="/errorPage" element={<ErrorPage/>}></Route>
                            {/*<Route path="/test/main" element={<MatchList />}></Route>*/}
                        </Routes>
                    </div>
                    <Footer/>
                </div>
            </BrowserView>
            <MobileView>
                <div id="wrapper">
                    <Header/>
                    <div id="content">
                        {a2hs}
                        <Routes>
                            <Route path="/main" element={<Main/>}></Route>
                            <Route path="/" element={<Join/>}></Route>
                            <Route path="/loginSuccess" element={<LoginSuccess/>}></Route>
                            <Route path="/loginHandler" element={<LoginHandler/>}></Route>
                            <Route path="/matchResultWrite/:matchId" element={<MatchResultWrite/>}></Route>
                            <Route path="/oauth2/code/kakao" element={<LoginHandler/>}></Route>
                            <Route path="/players" element={<Players/>}></Route>
                            <Route path="/records" element={<Records/>}></Route>
                            <Route path="/player/:memberId" element={<NewPlayer/>}></Route>
                            <Route path="/errorPage" element={<ErrorPage/>}></Route>
                            <Route path="/loading" element={<Loading />}></Route>
                            {/*<Route path="/test/main" element={<MatchList />}></Route>*/}
                        </Routes>
                    </div>
                    <Footer/>
                </div>
            </MobileView>
        </BrowserRouter>
    );
}

export default App;